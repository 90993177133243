import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Input, Radio, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Card from "components/ui/Card";
import styles from "./Filter.module.css";
import TimeRange from "components/ui/TimeRange";

function Filter({ onSearch }) {
  const { t } = useTranslation();
  const [dates, setDates] = useState(null);
  const [username, setUsername] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);

  const getPayload = () => {
    const payload = {};
    if (dates && dates.length) {
      const startDate = dates[0].format("YYYY-MM-DD HH:mm:ss");
      const endDate = dates[1].format("YYYY-MM-DD HH:mm:ss");
      payload.start_date = startDate;
      payload.end_date = endDate;
    }
    if (username && username !== "") {
      payload.username = username.trim();
    }

    if (isSuccess !== null) {
      payload.is_success = isSuccess;
    }

    return payload;
  };

  const handleSearch = () => {
    const payload = getPayload();
    onSearch(payload);
  };

  return (
    <Card>
      <span className="text_title">{t("FIND_CALL_LOG")}</span>
      <Row gutter={20}>
        <Col span={8}>
          <div className={styles.form_control}>
            <div className="label">{t("USERNAME")}</div>
            <Input
              placeholder={t("USERNAME")}
              className="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              allowClear
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("CREATED")}</span>
            <TimeRange dates={dates} onChangeDates={setDates} border />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("STATUS")}</span>
            <Radio.Group
              options={[
                {
                  label: t("SUCCESS"),
                  value: true,
                },
                {
                  label: t("FAIL"),
                  value: false,
                },
              ]}
              onChange={(e) => setIsSuccess(e.target.value)}
              value={isSuccess}
            />
          </div>
        </Col>
      </Row>

      <Row justify="end">
        <div className={styles.btn_container}>
          <Button
            icon={<SearchOutlined />}
            type="primary"
            onClick={() => handleSearch()}
          >
            {t("SEARCH")}
          </Button>
        </div>
      </Row>
    </Card>
  );
}

export default Filter;

export const Reason = {
  MATCH: 0,
  NOT_MATCH: 1,
  NOT_ENOUGH_NET_SPEECH: 2,
  NOT_ENOUGH_QUALITY: 3,
  MAX_CCU: 4,
  INVALID_AUDIO_FORMAT: 5,
  FAILED_TOO_MANY: 6,
};

export const getReason = (reason) => {
  if (reason === Reason.MATCH) {
    return "MATCH";
  } else if (reason === Reason.NOT_MATCH) {
    return "NOT_MATCH";
  } else if (reason === Reason.NOT_ENOUGH_NET_SPEECH) {
    return "NOT_ENOUGH_NET_SPEECH";
  } else if (reason === Reason.MAX_CCU) {
    return "MAX_CCU";
  } else if (reason === Reason.NOT_ENOUGH_QUALITY) {
    return "NOT_ENOUGH_QUALITY";
  } else if (reason === Reason.FAILED_TOO_MANY) {
    return "FAILED_TOO_MANY";
  } else if (reason === Reason.INVALID_AUDIO_FORMAT) {
    return "INVALID_AUDIO_FORMAT";
  } else {
    return "-";
  }
};

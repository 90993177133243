import React, { useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  UserOutlined,
  CloseOutlined,
  RightOutlined,
  DashboardOutlined,
  CustomerServiceOutlined,
  HistoryOutlined,
  PhoneOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { animated, useTransition } from "react-spring";
import { useTranslation } from "react-i18next";
import { Avatar, Popover, Button } from "antd";
import Scroll from "react-perfect-scrollbar";

import styles from "./Navigation.module.css";
import { globalAction } from "store/globalSlice";
import { useAuthProvider } from "contexts/authContext";
import UtilMenu from "./UtilMenu";
import useOutside from "hooks/useOutside";

const iconMapper = {
  "/dashboard": <DashboardOutlined />,
  "/authorization": <UserOutlined />,
  "/voice": <CustomerServiceOutlined />,
  "/history": <HistoryOutlined />,
  "/calling": <PhoneOutlined />,
  "/logging": <FileSearchOutlined />,
};

function UserInfo() {
  const { currentUser } = useAuthProvider();
  return (
    <>
      {currentUser && (
        <div className={styles.user_info_container}>
          <div className={styles.user_avatar}>
            <Avatar className={styles.avatar} size={32}>
              {currentUser.username[0].toUpperCase()}
            </Avatar>
          </div>
          <div className={styles.user_main}>
            <span className={styles.text_username}>{currentUser.username}</span>
            <span className={styles.text_role}>{currentUser.role}</span>
          </div>
          <div className={styles.user_action}>
            <Popover
              trigger="click"
              placement="rightBottom"
              content={<UtilMenu />}
            >
              <Button type="text" icon={<RightOutlined />} />
            </Popover>
          </div>
        </div>
      )}
    </>
  );
}

function Navigation() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser } = useAuthProvider();
  const isCollapse = useSelector((state) => state.global.isCollapse);
  const ref = useRef();

  const transition = useTransition(isCollapse, {
    enter: { left: "0rem" },
    from: { left: "-32rem" },
    leave: { left: "-32rem" },
  });

  useOutside(ref, [], () => {
    handleCloseNav();
  });

  const handleCloseNav = () => {
    dispatch(globalAction.setIsCollapse(false));
  };

  return (
    <>
      {transition((style, item) => {
        if (item) {
          return (
            <animated.div className={styles.container} style={style} ref={ref}>
              {isCollapse && (
                <div className={styles.close_btn} onClick={handleCloseNav}>
                  <CloseOutlined />
                </div>
              )}

              <Scroll className={styles.main}>
                {currentUser &&
                  currentUser.menu &&
                  currentUser.menu
                    .filter((item) => item.is_menu === true)
                    .map((item, idx) => (
                      <MenuItem
                        data={item}
                        key={idx}
                        t={t}
                        location={location}
                      />
                    ))}
              </Scroll>

              <div className={styles.user_info}>
                <UserInfo />
              </div>
            </animated.div>
          );
        }
        return "";
      })}
    </>
  );
}

function MenuItem({ data, t, location }) {
  const { path, name } = data;
  const currentPaths = location.pathname
    .split("/")
    .filter((item) => item && item !== "");

  const menuPaths = path.split("/").filter((item) => item && item !== "");
  const isSelected =
    menuPaths[0] === currentPaths[0] ||
    (menuPaths[0] === "dashboard" && currentPaths.length === 0);

  const containerStyles = isSelected
    ? {
        background: "#feb813",
        color: "#fff",
        fontWeight: 500,
      }
    : null;

  return (
    <Link
      to={path}
      className={styles.menu_item_container}
      style={containerStyles}
    >
      {iconMapper[path]}
      <span>{t(name)}</span>
    </Link>
  );
}

export default Navigation;

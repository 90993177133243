import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import styles from "./style.module.css";
import CallLog from "./components/CallLog";
import AuthenLog from "./components/AuthenLog";
import AuthorLog from "./components/AuthorLog";
import { useAuthProvider } from "contexts/authContext";

function Logging() {
  const { t } = useTranslation();
  const { currentUser } = useAuthProvider();
  const [tabs, setTabs] = useState([
    {
      label: t("CALL_LOG"),
      key: "1",
      children: <CallLog />,
    },
  ]);

  useEffect(() => {
    if (currentUser?.role === "admin") {
      const tmp = [
        ...tabs,
        {
          label: "Authentication logs",
          key: "2",
          children: <AuthenLog />,
        },
        {
          label: "Authorization logs",
          key: "3",
          children: <AuthorLog />,
        },
      ];
      setTabs(tmp);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Tabs defaultActiveKey="1" items={tabs} />
    </div>
  );
}

export default Logging;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SoundOutlined } from "@ant-design/icons";
import { ThreeDots } from "react-loader-spinner";
import styles from "./style.module.css";
import Enroll from "./components/Enroll";
import InvalidAgent from "./components/InvalidAgent";
import { TOKEN_KEY } from "constants/global";

import { useAuthProvider } from "contexts/authContext";
import { ProcessType, Env, Speaking } from "./status";
import Verify from "./components/Verify";
// import OverCCU from "./components/OverCCU";
import Error from "./components/Error";
import Heath from "../Heath";
import Loading from "../Loading";
import { getStatusUrl } from "utils/helper";

function Status({
  agentId,
  phone,
  cif,
  citizenId,
  phoneType,
  type,
  onSave,
  isLogin = true,
  setCallId,
  agentName,
  ucid,
  clientType = "DIN",
}) {
  const { t } = useTranslation();
  const { currentUser, loading } = useAuthProvider();
  const [status, setStatus] = useState([]);
  const [error, setError] = useState(null);
  const [processType, setProcessType] = useState(null);
  const [env, setEnv] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(null);

  useEffect(() => {
    console.log(isLogin);
    if (isLogin) {
      const url = getStatusUrl(agentId, cif, phone, agentName, ucid);
      const token = localStorage.getItem(TOKEN_KEY);
      const ws = new WebSocket(url);

      const closeWebsocket = (websocket) => {
        if (websocket.readyState == WebSocket.OPEN) {
          websocket.send("close");
          websocket.close();
        }
      };

      ws.onopen = () => {
        ws.send(JSON.stringify({ message: "connected", token: token }));
      };

      ws.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.hasOwnProperty("error") && !data.hasOwnProperty("status")) {
          setError(data.error);
        } else if (data.hasOwnProperty("close")) {
          closeWebsocket(ws);
        } else {
          const statusData = JSON.parse(data.status);
          setStatus(statusData);
          setProcessType(data.process_type);
          setError(null);
          setIsSpeaking(data.is_speaking);
          setEnv(data.env);
          if (setCallId) {
            setCallId(data.call_id);
          }
        }
      };

      return () => {
        closeWebsocket(ws);
      };
    }
  }, []);

  const render = () => {
    if (error !== null) {
      if (error === "INVALID_AGENT" || !isLogin) {
        return (
          <InvalidAgent
            message={t(error)}
            agentId={agentId}
            agentName={agentName}
            phone={phone}
            cif={cif}
            ucid={ucid}
            clientType={clientType}
            phoneType={phoneType}
            citizenId={citizenId}
          />
        );
      } else if (error === "MAX_CCU") {
        // return <OverCCU />;
        return (
          <Error
            title={t("OVER_CCU")}
            desc={t("OVER_CCU_WARNING")}
            status="error"
          />
        );
      } else if (error === "PHONE_NOT_MATCH") {
        return <Error title={t("PHONE_NOT_MATCH")} desc=" " status="error" />;
      } else if (error == "CIF_PHONE_ENROLLING") {
        return (
          <Error
            title={t("CUSTOMER_ENROLLED")}
            desc={t("CIF_PHONE_ENROLLING")}
            status="warning"
          />
        );
      } else {
        return <Error title={t(error)} desc=" " status="warning" />;
      }
    }

    if (currentUser) {
      if (type !== "iframe" && processType !== null && processType !== type) {
        return <Error title={t(`no_${type}_call`)} desc="" status="warning" />;
      } else if (processType === ProcessType.ENROLL) {
        return (
          <Enroll
            cif={cif}
            agentId={agentId}
            phone={phone}
            status={status}
            type={type}
            onSave={onSave}
            citizenId={citizenId}
            phoneType={phoneType}
            agentName={agentName}
            ucid={ucid}
          />
        );
      } else if (processType === ProcessType.VERIFY) {
        return (
          <Verify
            cif={cif}
            agentId={agentId}
            phone={phone}
            status={status}
            type={type}
            onSave={onSave}
            citizenId={citizenId}
            agentName={agentName}
            ucid={ucid}
          />
        );
      } else if (processType === null && status.length === 0) {
        return <Loading />;
      }
    } else if (!currentUser) {
      return (
        <InvalidAgent
          message={t("NOT_LOGIN")}
          agentId={agentId}
          agentName={agentName}
          phone={phone}
          cif={cif}
          ucid={ucid}
          clientType={clientType}
          phoneType={phoneType}
          citizenId={citizenId}
        />
      );
    } else {
      return <></>;
    }
  };

  const style = type === "iframe" ? styles.iframe_container : styles.container;
  return (
    <div className={style}>
      {render()}
      <div className={styles.status_bar}>
        {env && status.length > 0 && (
          <div className={styles.bar_item}>
            <span className="text">Môi trường:</span>
            <Heath color={Env.CLEAN === env ? "#2ecc71" : "#e74c3c"} />
          </div>
        )}
        {isSpeaking && status.length > 0 && (
          <div className={styles.bar_item} style={{ width: "3rem" }}>
            <SoundOutlined />

            {isSpeaking === Speaking.YES && (
              <ThreeDots
                height="20"
                width="20"
                radius="9"
                color="#2ecc71"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Status;

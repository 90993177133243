import "./App.css";
import { useEffect } from "react";
import Router from "router";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { AuthProvider } from "contexts/authContext";

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const lan = localStorage.getItem("i18n");
    if (!lan) {
      localStorage.setItem("i18n", "vi");
    } else {
      i18n.changeLanguage(lan);
    }
  }, []);
  return (
    <div className="App">
      <AuthProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#feb813",
              colorLink: "#feb813",
              colorLinkHover: "#F2D389",
              // colorTextLightSolid: "rgba(0, 0, 0, 0.65)",
            },
          }}
        >
          <Router />
        </ConfigProvider>
      </AuthProvider>
    </div>
  );
}

export default App;

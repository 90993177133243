export const TOKEN_KEY = "TOKEN";
export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_PAGE_SIZE = 20;
export const PhoneType = {
  MAIN: "main",
  SUB: "sub",
  NONE: "none",
};

export const ClientType = {
  DIN: "DIN",
  FIN: "FIN",
  STAFF: "STAFF",
};

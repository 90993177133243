import React, { useState, useEffect, useRef } from "react";
import Scroll from "react-perfect-scrollbar";
import styles from "./style.module.css";

import customerApi from "apis/customer";
import { showErrorNotification } from "components/ui/Notification";
import { DEFAULT_PAGE_SIZE } from "constants/global";
import { VoiceStatus } from "constants/voice";
import Filter from "./components/Filter";
import VoiceList from "./components/VoiceList";

function VoiceManagement() {
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [filterObject, setFilterObject] = useState({
    status: VoiceStatus.ENROLLED_AND_ENROLLING,
  });
  const scrollRef = useRef();

  useEffect(() => {
    handleLoadVoice();
  }, []);

  const handleExportVoice = (payload, completed) => {
    customerApi.exportVoices(payload, completed);
  };

  const handleLoadVoice = (payload, currentPage, currentPageSize) => {
    let reqPage, reqSize;
    if (currentPage !== undefined && currentPageSize !== undefined) {
      reqPage = currentPage;
      reqSize = currentPageSize;
    } else {
      reqPage = page;
      reqSize = pageSize;
    }
    let reqData = {};
    if (!payload) {
      reqData = { ...filterObject };
    } else {
      reqData = { ...payload };
      setFilterObject(payload);
    }

    setLoading(true);

    customerApi
      .getVoices(reqPage, reqSize, reqData)
      .then((resp) => {
        setLoading(false);
        setDataSource(resp.voices);
        setTotal(resp.total);
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };

  const handleChangePage = (currentPage, currentPageSize) => {
    setPage(currentPage);
    setPageSize(currentPageSize);
    handleLoadVoice(null, currentPage, currentPageSize);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  return (
    <Scroll
      className={styles.container}
      containerRef={(el) => (scrollRef.current = el)}
    >
      <Filter onSearch={handleLoadVoice} onExportReport={handleExportVoice} />
      <VoiceList
        page={page}
        pageSize={pageSize}
        dataSource={dataSource}
        onChangePage={handleChangePage}
        total={total}
        loading={loading}
        onReload={handleLoadVoice}
      />
    </Scroll>
  );
}

export default VoiceManagement;

import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <span className={styles.text_404}>404</span>
      <h1>{t("NOT_FOUND")}</h1>
      <span>{t("NOT_FOUND_DESC")}</span>
    </div>
  );
}

export default NotFound;

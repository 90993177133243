import React from "react";
import moment from "moment";
import { getReason } from "constants/history";
import { Table, Tooltip, Tag } from "antd";
import { useTranslation } from "react-i18next";

function VerifyResultTable({ dataSource, loading, onRow }) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("VERIFY_DATE"),
      dataIndex: "created",
      key: "created",
      align: "center",
      width: 150,
      render: (created) => {
        const date = moment(created).format("YYYY-MM-DD HH:mm");
        return <span className="text_medium">{date}</span>;
      },
    },
    {
      title: t("CIF"),
      dataIndex: "cif",
      key: "cif",
      align: "center",
      width: 150,
      render: (cif) => {
        return <span className="text_medium">{cif}</span>;
      },
    },
    {
      title: t("PHONE"),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: 150,
      render: (phone) => {
        return <span className="text_medium">{phone}</span>;
      },
    },
    {
      title: t("UCID"),
      dataIndex: "verify_call_id",
      align: "center",
      key: "verify_call_id",
      ellipsis: true,
      render: (verify_call_id) => {
        return (
          <Tooltip title={verify_call_id}>
            <span className="text_medium">{verify_call_id}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("PROCESSING_CHANNEL"),
      dataIndex: "source",
      key: "source",
      align: "center",
      ellipsis: true,
      width: 100,
      render: (source) => {
        let color = "green";
        if (source === "ivr") {
          color = "purple";
        } else if (source === "voicebot") {
          color = "orange";
        }

        return (
          <Tag
            color={color}
            style={{
              fontSize: "1.1rem",
            }}
          >
            {t(source)}
          </Tag>
        );
      },
    },
    {
      title: t("AGENT_NAME"),
      dataIndex: "agent_name",
      key: "agent_name",
      ellipsis: true,
      align: "center",
      render: (agent_name) => {
        return <span className="text_medium">{agent_name || "-"}</span>;
      },
    },
    {
      title: t("FIRST_SCORE"),
      dataIndex: "score",
      key: "score",
      ellipsis: true,
      align: "center",
      render: (score) => {
        return <span className="text_medium">{score || "-"}</span>;
      },
    },
    {
      title: t("SCORE_RANGE"),
      dataIndex: "score_range",
      key: "score_range",
      ellipsis: true,
      align: "center",
      render: (scoreRange) => {
        let rangeStr = "";
        if (scoreRange) {
          const [minScore, maxScore] = scoreRange;
          rangeStr = `${minScore}, ${maxScore}`;
        }
        return <span className="text_medium">{rangeStr || "-"}</span>;
      },
    },
    {
      title: t("TIME_AT_FIRST_PASS"),
      dataIndex: "time_at_first_show_score",
      key: "time_at_first_show_score",
      ellipsis: true,
      align: "center",
      render: (time_at_first_show_score) => {
        return (
          <span className="text_medium">{time_at_first_show_score || "-"}</span>
        );
      },
    },
    {
      title: t("TIME_AT_10S_NOT_PASS"),
      dataIndex: "time_at_10s_not_match",
      key: "time_at_10s_not_match",
      ellipsis: true,
      align: "center",
      render: (time_at_10s_not_match) => {
        return (
          <span className="text_medium">{time_at_10s_not_match || "-"}</span>
        );
      },
    },
    {
      title: t("VERIFY_BY_QUESTION"),
      dataIndex: "agent_verify",
      key: "agent_verify",
      align: "center",
      ellipsis: true,
      render: (isVerify) => {
        let msg;
        if (isVerify === null) {
          msg = "-";
        } else if (isVerify) {
          msg = "Pass";
        } else {
          msg = "Fail";
        }
        return <span className="text_medium">{msg}</span>;
      },
    },
    {
      title: t("REASON"),
      dataIndex: "reason",
      key: "reason",
      align: "center",
      ellipsis: true,
      render: (reason) => {
        const message = t(getReason(reason));
        const result = message || "-";
        return (
          <Tooltip title={result}>
            <span className="text_medium">{result}</span>
          </Tooltip>
        );
      },
    },

    {
      title: t("VOICE_REMOVE"),
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      ellipsis: true,
      render: (isActive) => {
        return (
          <span className="text_medium">{isActive ? t("NO") : t("YES")}</span>
        );
      },
    },
    {
      title: t("WORK_CODE"),
      dataIndex: "work_code",
      key: "work_code",
      align: "center",
      ellipsis: true,
      render: (work_code) => {
        const result = work_code || "-";
        return <span className="text_medium">{result}</span>;
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={false}
      bordered={false}
      onRow={onRow}
    />
  );
}

export default VerifyResultTable;

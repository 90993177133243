import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Divider } from "antd";
import moment from "moment";
import styles from "./VoiceDetail.module.css";
import customerApi from "apis/customer";
import userApi from "apis/user";
import { TOKEN_KEY } from "constants/global";
import AudioPlayer from "components/ui/AudioPlayer";

function VoiceDetail({ visible, setVisible, data, setData }) {
  const { t } = useTranslation();
  const [urls, setUrls] = useState([]);
  const [duration, setDuration] = useState(0);
  const [agentMapping, setAgentMapping] = useState(null);

  useEffect(() => {
    getAudioPaths();
    getAgentMapping();
  }, []);

  const getUrlFromPath = (path) => {
    const token = localStorage.getItem(TOKEN_KEY);
    // const url = `${
    //   process.env.REACT_APP_API_URL
    // }/api/v1/customers/voice_print/${[path]}/${token}/play`;
    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/customers/voice_print/${[path]}/play`;
    return url;
  };

  const getAgentMapping = () => {
    let agentIds = [];
    if (data.enroll_agent_id) {
      agentIds = [data.enroll_agent_id];
    } else {
      agentIds = ["voicebot"];
    }

    if (data.remove_agent_id) {
      agentIds.push(data.remove_agent_id);
    }
    userApi.getAgentIdMapping(agentIds).then((resp) => {
      setAgentMapping(resp);
    });
  };

  const getAudioPaths = () => {
    customerApi.getAudioPath(data.voice_print_id).then((resp) => {
      // setAudioPaths(resp.audio_paths);
      const tmpUrls = resp.audio_paths.map((item) => getUrlFromPath(item));
      setUrls(tmpUrls);
      setDuration(resp.duration);
    });
  };

  const handleClose = () => {
    setVisible(false);
    setData(null);
  };

  return (
    <Drawer
      title={t("VOICE_DETAIL")}
      open={visible}
      onClose={handleClose}
      width={500}
    >
      <div className={styles.container}>
        <div className={styles.metadata_container}>
          <span className="label">{t("ENROLL_UCID")}</span>
          <span className="text">{data.enroll_ucid || "-"}</span>
          <span className="label">{t("LAST_ENRICH_UCID")}</span>
          <span className="text">{data.enrich_ucid || "-"}</span>
        </div>
        <Divider />
        {/* <span className="label">{t("VOICE_PRINT")}</span> */}
        {urls && urls.length > 0 && (
          <AudioPlayer urls={urls} duration={duration} />
        )}

        {agentMapping && (
          <div className={styles.metadata_container}>
            <span className="label">{t("ENROLL_DATE")}</span>
            <span className="text">
              {moment(data.created).format("YYYY-MM-DD HH:ss")}
            </span>

            <span className="label">{t("AGENT_ENROLL")}</span>
            <span className="text">
              {agentMapping[data.enroll_agent_id || "voicebot"]}
            </span>

            {data.remove_agent_id && (
              <>
                <span className="label">{t("REMOVE_DATE")}</span>
                <span className="text">
                  {moment(data.remove_date).format("YYYY-MM-DD HH:ss")}
                </span>

                <span className="label">{t("REMOVE_AGENT")}</span>
                <span className="text">
                  {agentMapping[data.remove_agent_id] || data.remove_agent_id}
                </span>
              </>
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
}

export default VoiceDetail;

import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./style.module.css";
import Header from "./components/Header";
import HeaderExtra from "./components/HeaderExtra";
import Navigation from "./components/Navigation";

function Layout() {
  return (
    <div className={styles.container}>
      <Navigation />
      <div className={styles.top_container}>
        <Header />
        <HeaderExtra />
      </div>
      <div className={styles.main}>
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;

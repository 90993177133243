import axiosClient from "./axiosClient";

const dashboardApi = {
  getVerifyCount: (start, end) => {
    const url = `/api/v1/dashboard/verify_count?start=${start}&end=${end}`;
    return axiosClient.get(url);
  },
  getVerifyStatistic: (start, end) => {
    const url = `/api/v1/dashboard/statistic_verify?start=${start}&end=${end}`;
    return axiosClient.get(url);
  },
  getEnrollStatistic: (start, end) => {
    const url = `/api/v1/dashboard/statistic_enrollment?start=${start}&end=${end}`;
    return axiosClient.get(url);
  },
};

export default dashboardApi;

import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuthProvider } from "contexts/authContext";
import Layout from "components/layout";
import Dashboard from "pages/Dashboard";
import UserManagement from "pages/UserManagement";
import Login from "pages/Login";
import IframeStatus from "pages/IframeStatus";
import EnrollVoice from "pages/EnrollVoice";
import NotFound from "pages/NotFound";
import VoiceManagement from "pages/VoiceManagement";
import VerifyVoice from "pages/VerifyVoice";
import History from "pages/History";
import Calling from "pages/Calling";
import Logging from "pages/Logging";
import IframeLogin from "pages/IfameLogin";

function Router() {
  const { currentUser, loading } = useAuthProvider();

  const mapper = {
    "/authorization": <UserManagement />,
    "/enroll": <EnrollVoice />,
    "/dashboard": <Dashboard />,
    "/voice": <VoiceManagement />,
    "/verify": <VerifyVoice />,
    "/history": <History />,
    "/calling": <Calling />,
    "/logging": <Logging />,
  };

  const render = () => {
    // if (!loading) {

    // } else {
    //   return <></>;
    // }
    // return <></>;
    return (
      <Routes>
        {currentUser && currentUser.menu ? (
          <>
            <Route path="/status" element={<IframeStatus type="loggedin" />} />
            <Route path="/" element={<Layout />}>
              <Route index element={mapper[currentUser?.menu[0].path]} />

              {currentUser.menu &&
                currentUser.menu.map((item, idx) => {
                  return (
                    <Route
                      key={idx}
                      path={item.path}
                      element={mapper[item.path]}
                    />
                  );
                })}
              <Route path="*" element={<NotFound />} />
            </Route>
          </>
        ) : (
          <>
            {/* <Route dex path="/" element={<Login />} /> */}
            <Route index path="/login" element={<Login />} />
            <Route path="/status" element={<IframeStatus type="logout" />} />
            <Route path="/iframe-login" element={<IframeLogin />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </>
        )}
      </Routes>
    );
  };
  return <>{render()}</>;
}

export default Router;

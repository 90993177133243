import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Input, Radio, Button } from "antd";
import { SearchOutlined, FileExcelOutlined } from "@ant-design/icons";
import Card from "components/ui/Card";
import styles from "./Filter.module.css";
import TimeRange from "components/ui/TimeRange";
import callLogApi from "apis/callLog";

function Filter({ onSearch }) {
  const { t } = useTranslation();
  const [dates, setDates] = useState(null);
  const [callId, setCallId] = useState(null);
  const [step, setStep] = useState(null);
  const [passCondition, setPassCondition] = useState(null);
  const [callEnroll, setCallEnroll] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

  const getPayload = () => {
    const payload = {};
    if (callId && callId !== "") {
      payload.call_id = callId.trim();
    }
    if (dates && dates.length) {
      const startDate = dates[0].format("YYYY-MM-DD HH:mm:ss");
      const endDate = dates[1].format("YYYY-MM-DD HH:mm:ss");
      payload.start_date = startDate;
      payload.end_date = endDate;
    }
    if (step && step !== "") {
      payload.step = step.trim();
    }

    if (passCondition != null) {
      payload.pass_condition = passCondition;
    }

    if (callEnroll != null) {
      payload.call_enroll = callEnroll;
    }

    if (agentId && agentId !== "") {
      payload.agent_id = agentId.trim();
    }

    if (agentName && agentName !== "") {
      payload.agent_name = agentName.trim();
    }

    return payload;
  };

  const handleSearch = () => {
    const payload = getPayload();
    onSearch(payload);
  };

  const handleExport = () => {
    const payload = getPayload();
    setExportLoading(true);
    callLogApi.exportCallLog(
      payload,
      t("EXPORT_LOG_SUCCESS"),
      t("EXPORT_LOG_FAIL"),
      () => {
        setExportLoading(false);
      }
    );
  };

  return (
    <Card>
      <span className="text_title">{t("FIND_CALL_LOG")}</span>
      <Row gutter={10}>
        <Col span={8}>
          <div className={styles.form_control}>
            <div className="label">{t("UCID")}</div>
            <Input
              placeholder={t("UCID")}
              className="text"
              value={callId}
              onChange={(e) => setCallId(e.target.value)}
              allowClear
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("CREATED")}</span>
            <TimeRange dates={dates} onChangeDates={setDates} border />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("STEP")}</span>
            <Input
              placeholder={t("STEP")}
              className="text"
              value={step}
              onChange={(e) => setStep(e.target.value)}
              allowClear
            />
          </div>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={4}>
          <div className={styles.form_control}>
            <span className="label">{t("PASS_CONDITION")}</span>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={[
                {
                  label: t("YES"),
                  value: true,
                },
                {
                  label: t("NO"),
                  value: false,
                },
              ]}
              onChange={(e) => setPassCondition(e.target.value)}
              value={passCondition}
            />
          </div>
        </Col>
        <Col span={4}>
          <div className={styles.form_control}>
            <span className="label">{t("CLICK_ON_ENROLL")}</span>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={[
                {
                  label: t("YES"),
                  value: true,
                },
                {
                  label: t("NO"),
                  value: false,
                },
              ]}
              onChange={(e) => setCallEnroll(e.target.value)}
              value={callEnroll}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("AGENT_NAME")}</span>
            <Input
              placeholder={t("AGENT_NAME")}
              className="text"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              allowClear
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("AGENT_ID")}</span>
            <Input
              placeholder={t("AGENT_ID")}
              className="text"
              value={agentId}
              onChange={(e) => setAgentId(e.target.value)}
              allowClear
            />
          </div>
        </Col>
      </Row>

      <Row justify="end">
        <div className={styles.btn_container}>
          <Button
            icon={<FileExcelOutlined />}
            onClick={() => handleExport()}
            loading={exportLoading}
          >
            {t("EXPORT_LOG")}
          </Button>
          <Button
            icon={<SearchOutlined />}
            type="primary"
            onClick={() => handleSearch()}
          >
            {t("SEARCH")}
          </Button>
        </div>
      </Row>
    </Card>
  );
}

export default Filter;

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Table, Button, Pagination, Popconfirm, Tag, Tooltip } from "antd";
import { DeleteOutlined, AlignRightOutlined } from "@ant-design/icons";
import Card from "components/ui/Card";
import styles from "./VoiceList.module.css";
import customerApi from "apis/customer";
import {
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";
import VoiceDetail from "./VoiceDetail";
import { getStatus, VoiceStatus } from "constants/voice";

function VoiceList({
  page,
  pageSize,
  total,
  dataSource,
  onChangePage,
  loading,
  onReload,
}) {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [dataSource]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled: record.is_verifying === true || !record.is_active,
    }),
  };
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const columns = [
    {
      title: t("CIF"),
      dataIndex: "cif",
      key: "cif",
      align: "center",
      width: 150,
      render: (cif) => {
        return <span className="text_medium">{cif}</span>;
      },
    },
    {
      title: t("PHONE"),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: 150,
      render: (cif) => {
        return <span className="text_medium">{cif}</span>;
      },
    },
    {
      title: t("AGENT_ENROLL"),
      dataIndex: "agent_name",
      key: "agent_name",
      align: "center",
      render: (agent) => {
        return <span className="text_medium">{agent}</span>;
      },
    },
    {
      title: t("ENROLL_CHANNEL"),
      dataIndex: "source",
      key: "source",
      // align: "center",
      ellipsis: true,
      render: (source) => {
        let color = "green";
        if (source === "ivr") {
          color = "purple";
        } else if (source === "voicebot") {
          color = "orange";
        }
        return (
          <Tag color={color} style={{ fontSize: "1.1rem" }}>
            {t(source)}
          </Tag>
        );
      },
    },
    {
      title: t("ENROLL_DATE"),
      dataIndex: "created",
      key: "created",
      align: "center",
      width: 150,
      render: (created) => {
        const date = moment(created).format("YYYY-MM-DD HH:mm");
        return <span className="text_medium">{date}</span>;
      },
    },
    {
      title: t("STATUS"),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      ellipsis: true,
      render: (status) => {
        return <span className="text_medium">{t(getStatus(status))}</span>;
      },
    },
    {
      title: t("LAST_ENRICH_DATE"),
      dataIndex: "enrich_date",
      key: "enrich_date",
      align: "center",
      ellipsis: true,
      render: (enrich_date) => {
        let date = "";
        if (enrich_date) {
          date = moment(enrich_date).format("YYYY-MM-DD HH:mm");
        }
        return <span className="text_medium">{date || "-"}</span>;
      },
    },
    {
      title: t("ENRICH_COUNT"),
      dataIndex: "enrich_count",
      key: "enrich_count",
      align: "center",
      ellipsis: true,
      render: (enrich_count) => {
        return <span className="text_medium">{enrich_count || "-"}</span>;
      },
    },
    {
      title: t("IS_REMOVE"),
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      ellipsis: true,
      render: (isActive) => {
        return (
          <span className="text_medium">{isActive ? t("NO") : t("YES")}</span>
        );
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "key",
      key: "key",
      align: "center",
      ellipsis: true,
      width: 100,
      render: (key, row) => {
        return (
          <div className={styles.table_action_container}>
            <Popconfirm
              title={t("CONFIRM_REMOVE_VOICE")}
              onConfirm={(e) => {
                removeVoice([row.key]);
              }}
              okText={t("YES")}
              cancelText={t("NO")}
              disabled={
                row.is_verifying || row.status === VoiceStatus.REMOVE_VOICE
              }
            >
              <Tooltip title={t("CANCEL_VOICE")}>
                <Button
                  type="link"
                  size="small"
                  danger
                  disabled={row.is_verifying || !row.is_active}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>

            <Tooltip title={t("DETAIL")}>
              <Button
                type="link"
                size="small"
                icon={<AlignRightOutlined />}
                onClick={() => {
                  setVisibleDetail(true);
                  setSelectedData({
                    enrich_ucid: row.enrich_ucid,
                    enroll_ucid: row.enroll_ucid,
                    voice_print_id: row.voice_print_id,
                    remove_date: row.remove_date,
                    remove_agent_id: row.remove_agent_id,
                    created: row.created,
                    enroll_agent_id: row.enroll_agent_id,
                  });
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleRemoveVoice = (e) => {
    if (selectedRowKeys.length === 0) {
      return;
    }
    removeVoice(selectedRowKeys);
  };

  const removeVoice = (ids) => {
    if (ids.length === 0) {
      return;
    }
    customerApi
      .removeVoices({ ids: ids })
      .then((resp) => {
        showSuccessNotification(t("REMOVE_VOICE_SUCCESS"));
        onReload();
      })
      .catch((err) => {
        showErrorNotification(t(err?.detail));
      });
  };

  return (
    <>
      <Card>
        <span className="text-small-11">
          {t("TOTAL_RECORD")} {total}
        </span>
        <span className="text_title">{t("VOICE_LIST")}</span>

        <div className={styles.header_container}>
          <div className={styles.action_container}>
            <Popconfirm
              title={t("CONFIRM_REMOVE_VOICE")}
              onConfirm={handleRemoveVoice}
              okText={t("YES")}
              cancelText={t("NO")}
              disabled={selectedRowKeys.length === 0}
            >
              <Button
                type="primary"
                ghost
                disabled={selectedRowKeys.length === 0}
              >
                {t("CANCEL_VOICE")}
              </Button>
            </Popconfirm>

            {selectedRowKeys.length > 0 && (
              <span className="text">
                {t("NUM_VOICE_SELECTED")}: {selectedRowKeys.length}
              </span>
            )}
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
          rowSelection={rowSelection}
        />
        <div className="page_container">
          <Pagination
            total={total}
            current={page}
            pageSize={pageSize}
            onChange={onChangePage}
          />
        </div>
      </Card>
      {visibleDetail && selectedData && (
        <VoiceDetail
          visible={visibleDetail}
          setVisible={setVisibleDetail}
          data={selectedData}
          setData={setSelectedData}
        />
      )}
    </>
  );
}

export default VoiceList;

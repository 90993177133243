import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./VerifyResult.module.css";

function VerifyResult({
  fistPassedScore,
  sencondFailedScore,
  is10sNotMatch,
  is3TimeNotMatch,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h3>{t("VERIFY_RESULT")}</h3>
      {fistPassedScore !== null && fistPassedScore !== undefined && (
        <span className="text_success">
          {`${t("VOICE_MATCHED")}:`} {fistPassedScore}
        </span>
      )}
      {sencondFailedScore !== null && sencondFailedScore !== undefined && (
        <span className="text_error">
          {`${t("VOICE_NOT_MATCHED")}:`} {sencondFailedScore}
        </span>
      )}
      {is10sNotMatch !== null && is10sNotMatch !== undefined && (
        <span className="text_error">{t("last_10s_not_match")}</span>
      )}
      {is3TimeNotMatch !== null && is3TimeNotMatch !== undefined && (
        <span className="text_error">{t("not_match_3times_in_day")}</span>
      )}
    </div>
  );
}

export default VerifyResult;

import axiosClient from "./axiosClient";
import Axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { TOKEN_KEY } from "constants/global";
import {
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";

const userApi = {
  getUsers: (payload, page, pageSize) => {
    const url = `/api/v1/auth/users?page=${page}&size=${pageSize}`;
    return axiosClient.post(url, payload);
  },
  updateUserRole: (payload) => {
    const url = "/api/v1/auth/users";
    return axiosClient.put(url, payload);
  },
  getRoles: () => {
    const url = "/api/v1/auth/roles";
    return axiosClient.get(url);
  },
  getAllPermissions: () => {
    const url = "/api/v1/auth/permissions";
    return axiosClient.get(url);
  },
  editPermissions: (payload) => {
    const url = `/api/v1/auth/roles`;
    return axiosClient.put(url, payload);
  },
  createRole: (payload) => {
    const url = "/api/v1/auth/roles";
    return axiosClient.post(url, payload);
  },
  deleteRole: (roleName) => {
    const payload = {
      name: roleName,
    };
    const url = `/api/v1/auth/roles/delete`;
    return axiosClient.put(url, payload);
  },
  getAgentIdMapping: (agentIds) => {
    const url = "/api/v1/auth/users/mapping";
    const payload = {
      agent_ids: agentIds,
    };
    return axiosClient.post(url, payload);
  },
  exportUser: (payload, successMessage, errorMessage) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/auth/users/export`;
    const token = localStorage.getItem(TOKEN_KEY);
    Axios.post(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const now = moment().format("YYYY-MM-DD HH:mm");
        const filename = `user_${now}.xlsx`;
        fileDownload(res.data, filename);
        showSuccessNotification(successMessage);
      })
      .catch((err) => {
        showErrorNotification(errorMessage);
      });
  },
};

export default userApi;

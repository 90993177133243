import React from "react";
import { Avatar, Popover } from "antd";
import { Link } from "react-router-dom";

import styles from "./Header.module.css";
import UtilMenu from "./UtilMenu";
import Logo from "assets/images/logo.png";
import { useAuthProvider } from "contexts/authContext";

function Header() {
  const { currentUser } = useAuthProvider();

  return (
    <div className={styles.container}>
      <Link to="/" className={styles.logo_container}>
        <img src={Logo} className={styles.logo} />
        <span className={styles.text_logo}>VoiceDNA</span>
      </Link>

      {currentUser && (
        <Popover trigger="click" placement="bottomRight" content={<UtilMenu />}>
          <Avatar className={styles.avatar} size={28}>
            {currentUser.username[0].toUpperCase()}
          </Avatar>
        </Popover>
      )}
    </div>
  );
}

export default Header;

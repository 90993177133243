import styles from "./style.module.css";
import Logo from "assets/images/logo.png";
import { LoadingOutlined } from "@ant-design/icons";

function Loading() {
  return (
    <div className={styles.container}>
      <LoadingOutlined className={styles.loading_icon} />
      <div className={styles.logo_container}>
        <img src={Logo} className={styles.image} />
      </div>
    </div>
  );
}

export default Loading;

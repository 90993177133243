import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useAuthProvider } from "contexts/authContext";
import styles from "./InvalidAgent.module.css";

function InvalidAgent({
  message,
  agentId,
  agentName,
  phone,
  cif,
  ucid,
  clientType,
  phoneType,
  citizenId,
}) {
  const { logout } = useAuthProvider();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirectToLogin = () => {
    const isRedirectToLogin = false;
    const token = localStorage.getItem("TOKEN");
    if (token) {
      logout(isRedirectToLogin);
    }
    const url = `/iframe-login?agentId=${agentId}&username=${agentName}&citizenId=${citizenId}&cif=${cif}&phone=${phone}&phoneType=${phoneType}&clientType=${clientType}&ucid=${ucid}`;
    localStorage.removeItem("TOKEN");
    navigate(url);
    // window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={styles.container}>
      <div className={styles.message_container}>
        <span className={styles.message}>{message}</span>
      </div>
      <div className={styles.action_container}>
        <Button type="primary" onClick={redirectToLogin}>
          {t("LOGIN")}
        </Button>
      </div>
    </div>
  );
}

export default InvalidAgent;

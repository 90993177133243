import moment from "moment";

export function convertSecondToTime(secs) {
  let sec_num = parseInt(secs, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

export function convertTimestampToDate(ts) {
  const date = new Date(ts);
  const year = date.getFullYear();
  const month = "0" + (date.getMonth() + 1);
  const day = "0" + date.getDate();
  const hour = "0" + date.getHours();
  const minute = "0" + date.getMinutes();
  const second = "0" + date.getSeconds();

  return `${year}/${month.substr(-2)}/${day.substr(-2)} ${hour.substr(
    -2
  )}:${minute.substr(-2)}:${second.substr(-2)}`;
}

export function getDatesLastNMonths(n) {
  const startDate = moment().subtract(n, "months").startOf("day");
  const endDate = moment();
  return { start: startDate, end: endDate };
}

export function getDatesLastNWeeks(n) {
  const startDate = moment().subtract(n, "weeks").startOf("day");
  const endDate = moment();
  return { start: startDate, end: endDate };
}

export function getDatesLastNDays(n) {
  const startDate = moment().subtract(n, "days").startOf("day");
  const endDate = moment();
  return { start: startDate, end: endDate };
}

export function getTime(n, direction, type) {
  let startDate, endDate;
  if (direction === -1) {
    startDate = moment().subtract(n, type).startOf("day");
    endDate = moment();
  } else {
    startDate = moment();
    endDate = moment().add(n, type).endOf("day");
  }

  return { start: startDate, end: endDate };
}

export function toHHMMSS(secs) {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

export function toTimestamp(dateString) {
  if (dateString && typeof dateString === "string") {
    // return Date.parse(dateString.replace(/\s/, "T")) / 1000;
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").unix();
  }
}

export function convertMomentToString(date, fmt = "YYYY-MM-DD HH:mm:ss") {
  return date.format(fmt);
}

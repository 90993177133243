import React, { useState, useEffect } from "react";
import { Row, Col, Statistic, Card } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./VerifyByDay.module.css";
import dashboardApi from "apis/dashboard";
import { showErrorNotification } from "components/ui/Notification";
import { convertMomentToString } from "utils/datetime";

function CountStatistic({ data, title }) {
  const { t } = useTranslation();
  return (
    <Row gutter={8}>
      <Col span={3} className={styles.item_container}>
        <div className={styles.statistic_title}>
          <span>{title}</span>
        </div>
      </Col>
      {data?.map((item, idx) => (
        <Col span={7} key={idx}>
          <Card>
            <Statistic title={t(item.title)} value={item.value} />
          </Card>
        </Col>
      ))}
    </Row>
  );
}

function VerifyByDay({ dates }) {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dates && dates.length > 1) {
      getVerifyStatisticData(dates);
    }
  }, [dates]);

  const getVerifyStatisticData = (queryDates) => {
    if (!queryDates || queryDates.length === 0) {
      showErrorNotification(t("REQUIRED_SELECT_TIME"));
      return;
    }
    const start = convertMomentToString(queryDates[0]);
    const end = convertMomentToString(queryDates[1]);
    setLoading(true);
    dashboardApi.getVerifyCount(start, end).then((resp) => {
      setLoading(false);
      setData(resp);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className="text_title">{t("NUM_VERIFY_BY_DAY")}</span>
      </div>

      <div className={styles.main}>
        <CountStatistic data={data?.ivr} title="IVR" />
        <CountStatistic data={data?.voicebot} title="Voicebot" />
        <CountStatistic data={data?.call_center} title="Inbound" />
      </div>
    </div>
  );
}

export default VerifyByDay;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  statisticData: null,
  filterDates: null,
};
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStatisticData: (state, action) => {
      state.statisticData = action.payload;
    },
    setFilterDates: (state, action) => {
      state.filterDates = action.payload;
    },
  },
});

export const dashboardAction = dashboardSlice.actions;

export default dashboardSlice.reducer;

import React from "react";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import Scroll from "react-perfect-scrollbar";
import styles from "./Detail.module.css";
import { TOKEN_KEY } from "constants/global";
import SingleAudioPlayer from "components/ui/AudioPlayer/SingleAudioPlayer";

function Detail({
  visible,
  setVisible,
  selectedAudioPaths,
  setSelectedAudioPaths,
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    setVisible(false);
    setSelectedAudioPaths(null);
  };

  return (
    <Drawer
      title={t("VERIFY_AUDIO")}
      open={visible}
      onClose={handleClose}
      width={500}
    >
      <Scroll className={styles.container}>
        {selectedAudioPaths.map((path, idx) => {
          return <SingleAudioPlayer key={idx} filePath={path} />;
        })}
      </Scroll>
    </Drawer>
  );
}

export default Detail;

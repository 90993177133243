import React, { useState } from "react";
import { Table, Button, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Card from "components/ui/Card";
import styles from "./CallList.module.css";
import Log from "./Log";
import { useAuthProvider } from "contexts/authContext";

function CallList({
  dataSource,
  loading,
  page,
  pageSize,
  onChangePage,
  total,
}) {
  const { t } = useTranslation();
  const { currentUser } = useAuthProvider();

  const [visibleLog, setVisibleLog] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);

  const columns = [
    {
      title: t("CREATED"),
      dataIndex: "created",
      key: "created",
      align: "center",
      width: 150,
      render: (created) => {
        const date = moment(created).format("YYYY-MM-DD HH:mm");
        return <span className="text_medium">{date}</span>;
      },
    },
    {
      title: t("AGENT_NAME"),
      dataIndex: "agent_name",
      key: "agent_name",
      align: "center",
      render: (agent_name) => {
        return <span className="text_medium">{agent_name || "-"}</span>;
      },
    },
    {
      title: t("UCID"),
      dataIndex: "call_id",
      key: "call_id",
      align: "center",
      render: (ucid) => {
        return <span className="text_medium">{ucid}</span>;
      },
    },
    {
      title: t("STEP"),
      dataIndex: "step",
      key: "step",
      align: "center",
      render: (step) => {
        return <span className="text_medium">{step || "-"}</span>;
      },
    },
    {
      title: "Pass condition",
      dataIndex: "pass_condition",
      key: "pass_condition",
      align: "center",
      render: (isPassCondition) => {
        let msg;
        if (isPassCondition === null) {
          msg = "-";
        } else if (isPassCondition) {
          msg = t("YES");
        } else {
          msg = t("NO");
        }
        return <span className="text_medium">{msg}</span>;
      },
    },
    {
      title: "Pass duration",
      dataIndex: "pass_duration",
      key: "pass_duration",
      align: "center",
      render: (pass_duration) => {
        return <span className="text_medium">{pass_duration}</span>;
      },
    },
    {
      title: t("CLICK_ON_ENROLL"),
      dataIndex: "call_enroll",
      key: "call_enroll",
      align: "center",
      render: (isclickEnroll) => {
        let msg;
        if (isclickEnroll === null) {
          msg = "-";
        } else if (isclickEnroll) {
          msg = t("YES");
        } else {
          msg = t("NO");
        }
        return <span className="text_medium">{msg}</span>;
      },
    },
  ];

  if (currentUser?.role === "admin") {
    columns.push({
      title: t("ACTION"),
      dataIndex: "log",
      key: "log",
      align: "center",
      width: 50,
      render: (log, row) => {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => {
              setVisibleLog(true);
              setSelectedLog({
                callId: row.call_id,
                log: log,
              });
            }}
          >
            view
          </Button>
        );
      },
    });
  }
  return (
    <Card>
      <span className="text_title">{t("CALL_LOG_LIST")}</span>
      <span className="text-small-11">
        {t("TOTAL_RECORD")} {total}
      </span>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={false}
      />
      <div className={styles.pagination_container}>
        <Pagination
          current={page}
          pageSize={pageSize}
          total={total}
          onChange={onChangePage}
        />
      </div>

      {visibleLog && selectedLog !== null && (
        <Log
          visible={visibleLog}
          setVisible={setVisibleLog}
          data={selectedLog}
          setData={setSelectedLog}
        />
      )}
    </Card>
  );
}

export default CallList;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Scroll from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";
import CustomerInfo from "components/ui/CustomerInfo";
import VoiceInfo from "./components/VoiceInfo";
import SameCustomerList from "./components/SameCustomerList";
import Error from "components/ui/Status/components/Error";
import { PhoneType } from "constants/global";

function EnrollVoice() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [agentId, setAgentId] = useState(null);
  const [phone, setPhone] = useState(null);
  const [cif, setCif] = useState(null);
  const [citizenId, setCitizenId] = useState(null);

  const [validInfo, setValidInfo] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [voiceId, setVoiceId] = useState(null);
  const [phoneType, setPhoneType] = useState(null);
  const [callId, setCallId] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [ucid, setUcid] = useState(null);

  useEffect(() => {
    const id = searchParams.get("agentId");
    const cifCode = searchParams.get("cif");
    const phoneNumber = searchParams.get("phone");
    const citizen = searchParams.get("citizenId");
    const type = searchParams.get("phoneType");
    const username = searchParams.get("agentName");
    const callId = searchParams.get("ucid");

    if (
      id &&
      cifCode &&
      phoneNumber &&
      citizen &&
      type &&
      username &&
      callId &&
      [PhoneType.MAIN, PhoneType.SUB].includes(type)
    ) {
      setAgentId(id);
      setPhone(phoneNumber);
      setCif(cifCode);
      setCitizenId(citizen);
      setPhoneType(type);
      setValidInfo(true);
      setAgentName(username);
      setUcid(callId);
    } else {
      setValidInfo(false);
    }
  }, []);

  const setEnrolledInfo = (enrolledStatus, enrolledVoiceId) => {
    setIsEnrolled(enrolledStatus);
    if (enrolledStatus) {
      if (enrolledVoiceId) {
        setVoiceId(enrolledVoiceId);
      }
    }
  };

  return (
    <Scroll className={styles.container}>
      {validInfo === true && (
        <>
          <CustomerInfo
            cif={cif}
            phone={phone}
            citizenId={citizenId}
            callId={callId}
          />
          <VoiceInfo
            agentId={agentId}
            cif={cif}
            phone={phone}
            agentName={agentName}
            setEnrolledInfo={setEnrolledInfo}
            setCallId={setCallId}
            ucid={ucid}
          />

          {isEnrolled &&
            voiceId &&
            citizenId &&
            cif &&
            phoneType === PhoneType.MAIN && (
              <SameCustomerList
                phone={phone}
                voiceId={voiceId}
                citizenId={citizenId}
                cif={cif}
              />
            )}
        </>
      )}

      {validInfo === false && (
        <Error
          title={t("INVALID_CUSTOMER_PARAM")}
          desc={t("CUSTOMER_PARAM_EX")}
          status="error"
        />
      )}
    </Scroll>
  );
}

export default EnrollVoice;

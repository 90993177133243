import React, { useState, useEffect } from "react";
import { Input, Select, Checkbox, Button, Col, Row } from "antd";
import { SearchOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./Filter.module.css";
import Card from "components/ui/Card";
import TimeRange from "components/ui/TimeRange";

const { Option } = Select;

function Filter({ onSearch, onExportReport }) {
  const { t } = useTranslation();
  const [cif, setCif] = useState(null);
  const [phone, setPhone] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [dates, setDates] = useState(null);
  const [selectedVerifyChannel, setSelectedVerifyChannel] = useState("all");
  const [statusValues, setStatusValues] = useState([]);
  const [callId, setCallId] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  const getPayload = () => {
    const payload = {};
    if (cif && cif !== "") {
      payload.cif = cif.trim();
    }
    if (phone && phone !== "") {
      payload.phone = phone.trim();
    }
    if (agentName && agentName !== "") {
      payload.agent = agentName.trim();
    }

    if (callId && callId !== "") {
      payload.call_id = callId.trim();
    }

    if (dates && dates.length) {
      const startDate = dates[0].format("YYYY-MM-DD HH:mm:ss");
      const endDate = dates[1].format("YYYY-MM-DD HH:mm:ss");
      payload.start_date = startDate;
      payload.end_date = endDate;
    }
    if (selectedVerifyChannel && selectedVerifyChannel !== "all") {
      payload.verify_channel = selectedVerifyChannel;
    }

    if (statusValues && statusValues.length > 0) {
      payload.status = statusValues;
    }
    return payload;
  };

  const handleSearch = () => {
    const payload = getPayload();
    onSearch(payload);
  };

  const handleExport = () => {
    const payload = getPayload();
    setExportLoading(true);
    onExportReport(payload, () => {
      setExportLoading(false);
    });
  };

  return (
    <Card>
      <span className="text_title">{t("FIND_HISTORY")}</span>
      <div className={styles.main}>
        <div className={styles.info_filter}>
          <div className={styles.form_control}>
            <span className="label">{t("CIF")}</span>
            <Input
              placeholder={t("CIF")}
              className="text"
              value={cif}
              onChange={(e) => setCif(e.target.value)}
              allowClear
            />
          </div>

          <div className={styles.form_control}>
            <span className="label">{t("VERIFY_TIME")}</span>
            <TimeRange dates={dates} onChangeDates={setDates} border />
          </div>

          <div className={styles.form_control}>
            <span className="label">{t("AGENT_NAME")}</span>
            <Input
              className="text"
              placeholder={t("AGENT_NAME")}
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              allowClear
            />
          </div>

          <div className={styles.form_control}>
            <div className="label">{t("PHONE")}</div>
            <Input
              placeholder={t("PHONE")}
              className="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              allowClear
            />
          </div>

          <div className={styles.form_control}>
            <span className="label">{t("VERIFY_CHANNEL")}</span>
            <Select
              placeholder={t("VERIFY_CHANNEL")}
              className="text"
              value={selectedVerifyChannel}
              onChange={setSelectedVerifyChannel}
              allowClear
            >
              <Option value="all" className="text">
                {t("ALL")}
              </Option>
              <Option value="agent" className="text">
                {t("AGENT")}
              </Option>
              <Option value="voicebot" className="text">
                {t("VOICEBOT")}
              </Option>
              <Option value="ivr" className="text">
                {t("IVR")}
              </Option>
            </Select>
          </div>

          <div className={styles.form_control}>
            <div className="label">{t("UCID")}</div>
            <Input
              placeholder={t("UCID")}
              className="text"
              value={callId}
              onChange={(e) => setCallId(e.target.value)}
              allowClear
            />
          </div>
        </div>

        <div className={styles.status_filter}>
          <div className={styles.form_control}>
            <span className="label">{t("RESULT")}</span>
            <Checkbox.Group
              className="text"
              value={statusValues}
              onChange={setStatusValues}
            >
              <Row>
                <Col span={24}>
                  <Checkbox className="text" value="not_match_3times_in_day">
                    {t("3_TIME_NOT_MATCH")}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox className="text" value="last_10s_not_match">
                    {t("10S_NOT_MATCH")}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox className="text" value="over_ccu">
                    {t("OVER_CCU")}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox className="text" value="not_pass_verify">
                    {t("NOT_PASS_VERIFY")}
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      </div>

      <div className={styles.action_container}>
        <Button
          onClick={handleExport}
          icon={<FileExcelOutlined />}
          loading={exportLoading}
        >
          {t("EXPORT_REPORT")}
        </Button>
        <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
          {t("SEARCH")}
        </Button>
      </div>
    </Card>
  );
}

export default Filter;

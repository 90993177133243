import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Scroll from "react-perfect-scrollbar";
import styles from "./style.module.css";
import CustomerInfo from "components/ui/CustomerInfo";
import VerifyInfo from "./components/VerifyInfo";
import History from "./components/History";
import Error from "components/ui/Status/components/Error";

function VerifyVoice() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [agentId, setAgentId] = useState(null);
  const [phone, setPhone] = useState(null);
  const [cif, setCif] = useState(null);
  const [citizenId, setCitizenId] = useState(null);
  const [validInfo, setValidInfo] = useState(null);
  const [callId, setCallId] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [ucid, setUcid] = useState(null);

  useEffect(() => {
    const id = searchParams.get("agentId");
    const cifCode = searchParams.get("cif");
    const phoneNumber = searchParams.get("phone");
    const citizen = searchParams.get("citizenId");
    const username = searchParams.get("agentName");
    const callId = searchParams.get("ucid");

    if (id && cifCode && phoneNumber && citizen && username && callId) {
      setAgentId(id);
      setPhone(phoneNumber);
      setCif(cifCode);
      setValidInfo(true);
      setCitizenId(citizen);
      setAgentName(username);
      setUcid(callId);
    } else {
      setValidInfo(false);
    }
  }, []);

  return (
    <Scroll className={styles.container}>
      {validInfo === true && (
        <>
          <CustomerInfo
            cif={cif}
            phone={phone}
            citizenId={citizenId}
            callId={callId}
          />
          <VerifyInfo
            agentId={agentId}
            cif={cif}
            phone={[phone]}
            setCallId={setCallId}
            agentName={agentName}
            ucid={ucid}
          />
          <History cif={cif} phone={phone} />
        </>
      )}

      {validInfo === false && (
        <Error
          title={t("INVALID_CUSTOMER_PARAM")}
          desc={t("CUSTOMER_PARAM_EX")}
          status="error"
        />
      )}
    </Scroll>
  );
}

export default VerifyVoice;

import React from "react";
import { Table, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Card from "components/ui/Card";
import styles from "./AuthenList.module.css";

function AuthenList({
  dataSource,
  loading,
  page,
  pageSize,
  onChangePage,
  total,
}) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("CREATED"),
      dataIndex: "created",
      key: "created",
      align: "center",
      width: 150,
      render: (created) => {
        const date = moment(created).format("YYYY-MM-DD HH:mm");
        return <span className="text_medium">{date}</span>;
      },
    },
    {
      title: t("USERNAME"),
      dataIndex: "username",
      key: "username",
      align: "center",
      render: (agent_name) => {
        return <span className="text_medium">{agent_name || "-"}</span>;
      },
    },
    {
      title: t("ROLE"),
      dataIndex: "role",
      key: "role",
      align: "center",
      render: (role) => {
        return <span className="text_medium">{role}</span>;
      },
    },
    {
      title: t("DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (description) => {
        return <span className="text_medium">{description || "-"}</span>;
      },
    },
  ];
  return (
    <Card>
      <span className="text_title">{t("CALL_LOG_LIST")}</span>
      <span className="text-small-11">
        {t("TOTAL_RECORD")} {total}
      </span>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={false}
      />
      <div className={styles.pagination_container}>
        <Pagination
          current={page}
          pageSize={pageSize}
          total={total}
          onChange={onChangePage}
        />
      </div>
    </Card>
  );
}

export default AuthenList;

import axiosClient from "./axiosClient";

const voiceApi = {
  enroll: (ucid, phone) => {
    const url = `/api/v1/voice/enroll?session_id=${ucid}&phone_number=${phone}`;
    return axiosClient.post(url, {});
  },
  restartCall: (ucid) => {
    const url = `/api/v1/voice/restart?session_id=${ucid}`;
    return axiosClient.post(url, {});
  },
};

export default voiceApi;

import axiosClient from "./axiosClient";
import Axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { TOKEN_KEY } from "constants/global";
import {
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";

const customerApi = {
  getCustomerInfo: (cif, phone) => {
    const url = `/api/v1/customers/cif/${cif}/phone/${phone}`;
    return axiosClient.get(url);
  },
  getCustomersSamePhone: (cif, phone, citizenId) => {
    const url = `/api/v1/customers/cif/${cif}/phone/${phone}/citizen_id/${citizenId}`;
    return axiosClient.get(url);
  },
  linkVoiceToCustomer: (payload) => {
    const url = `/api/v1/customers/link`;
    return axiosClient.post(url, payload);
  },
  // linkMutipleVoiceToCustomer: (payload) => {
  //   const url = `/api/v1/customers/links`;
  //   return axiosClient.post(url, payload);
  // },
  getVoices: (page, pageSize, payload) => {
    const url = `/api/v1/customers/voices?page=${page}&size=${pageSize}`;
    return axiosClient.post(url, payload);
  },
  removeVoices: (payload) => {
    const url = "/api/v1/customers/voices";
    return axiosClient.put(url, payload);
  },
  getCustomerHistories: (cif, phone, page, pageSize) => {
    const url = `/api/v1/customers/histories/cif/${cif}/phone/${phone}?page=${page}&size=${pageSize}`;
    return axiosClient.get(url);
  },
  getHistories: (payload, page, pageSize) => {
    const url = `/api/v1/customers/histories?page=${page}&size=${pageSize}`;
    return axiosClient.post(url, payload);
  },
  exportHistory: (payload, completed) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/customers/histories/export`;
    const token = localStorage.getItem(TOKEN_KEY);
    Axios.post(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const now = moment().format("YYYY-MM-DD HH:mm");
        const filename = `history_${now}.xlsx`;
        fileDownload(res.data, filename);
        showSuccessNotification("Xuất báo cáo lịch sử xác thực thành công");
        completed();
      })
      .catch((err) => {
        showErrorNotification(
          "Bạn không có quyền sử dụng chức năng này. Vui lòng liên hệ với quản trị viên để được cấp quyền sử dụng"
        );
        completed();
      });
  },
  exportVoices: (payload, completed) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/customers/voices/export`;
    const token = localStorage.getItem(TOKEN_KEY);
    Axios.post(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const now = moment().format("YYYY-MM-DD HH:mm");
        const filename = `voices_${now}.xlsx`;
        fileDownload(res.data, filename);
        showSuccessNotification("Xuất báo cáo thông tin mẫu giọng thành công");
        completed();
      })
      .catch((err) => {
        showErrorNotification(
          "Bạn không có quyền sử dụng chức năng này. Vui lòng liên hệ với quản trị viên để được cấp quyền sử dụng"
        );
        completed();
      });
  },
  getAudioPath: (voicePrintId) => {
    const url = `/api/v1/customers/voice_print/${voicePrintId}/audio`;
    return axiosClient.get(url);
  },

  getAudioFileBlob: async (filePath) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/customers/voice_print/${filePath}/test`;
    const token = localStorage.getItem(TOKEN_KEY);
    try {
      const response = await Axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = new Blob([response.data], { type: "audio/wav" });
      return blob;
    } catch (error) {
      console.log("Error when get audio file blob", error);
      return null;
    }
  },
};

export default customerApi;

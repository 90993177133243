import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import styles from "./index.module.css";

function CustomerInfo({ cif, phone, citizenId, callId }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <span className="text_title">{t("CUSTOMER_INFO")}</span>
      <div className={styles.container}>
        <div className={styles.form_control}>
          <span className="label">{t("CIF")}</span>
          <Input placeholder={t("CIF")} value={cif} className="text" />
        </div>

        <div className={styles.form_control}>
          <span className="label">{t("PHONE")}</span>
          <Input placeholder={t("PHONE")} value={phone} className="text" />
        </div>

        <div className={styles.form_control}>
          <span className="label">{t("CMND")}</span>
          <Input placeholder={t("CMND")} value={citizenId} className="text" />
        </div>

        <div className={styles.form_control}>
          <span className="label">{t("UCID")}</span>
          <Input placeholder={t("UCID")} value={callId} className="text" />
        </div>
      </div>
    </div>
  );
}

export default CustomerInfo;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Input, Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Card from "components/ui/Card";
import styles from "./Filter.module.css";
import TimeRange from "components/ui/TimeRange";
import userApi from "apis/user";

const { Option } = Select;

function Filter({ onSearch }) {
  const { t } = useTranslation();
  const [dates, setDates] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    userApi.getRoles().then((resp) => {
      setRoles(resp);
    });
  }, []);

  const getPayload = () => {
    const payload = {};
    if (dates && dates.length) {
      const startDate = dates[0].format("YYYY-MM-DD HH:mm:ss");
      const endDate = dates[1].format("YYYY-MM-DD HH:mm:ss");
      payload.start_date = startDate;
      payload.end_date = endDate;
    }
    if (agentName && agentName !== "") {
      payload.agent_name = agentName.trim();
    }

    if (agentId && agentId !== "") {
      payload.agent_id = agentId.trim();
    }

    if (role !== null) {
      payload.role = role;
    }

    return payload;
  };

  const handleSearch = () => {
    const payload = getPayload();
    onSearch(payload);
  };

  return (
    <Card>
      <span className="text_title">{t("FIND_CALL_LOG")}</span>
      <Row gutter={20}>
        <Col span={8}>
          <div className={styles.form_control}>
            <div className="label">{t("AGENT_NAME")}</div>
            <Input
              placeholder={t("AGENT_NAME")}
              className="text"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              allowClear
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("AGENT_ID")}</span>
            <Input
              placeholder={t("AGENT_ID")}
              className="text"
              value={agentId}
              onChange={(e) => setAgentId(e.target.value)}
              allowClear
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("ROLE")}</span>
            <Select
              placeholder={t("ROLE")}
              className="text"
              value={role}
              onChange={setRole}
              allowClear
            >
              {roles.map((item, idx) => (
                <Option key={idx} value={item.role}>
                  {item.role}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("CREATED")}</span>
            <TimeRange dates={dates} onChangeDates={setDates} border />
          </div>
        </Col>
        <Col span={16}>
          <div className={styles.btn_container}>
            <Button
              icon={<SearchOutlined />}
              type="primary"
              onClick={() => handleSearch()}
            >
              {t("SEARCH")}
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default Filter;

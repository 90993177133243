import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Select, Button } from "antd";
import { SearchOutlined, FileExcelOutlined } from "@ant-design/icons";
import styles from "./Filter.module.css";
import Card from "components/ui/Card";
import { VoiceStatus } from "constants/voice";
import TimeRange from "components/ui/TimeRange";

const { Option } = Select;

function Filter({ onSearch, onExportReport }) {
  const { t } = useTranslation();

  const [cif, setCif] = useState(null);
  const [phone, setPhone] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [dates, setDates] = useState(null);
  const [citizenId, setCitizenId] = useState(null);
  const [selectedEnrollChannel, setSelectedEnrollChannel] = useState("all");
  const [status, setStatus] = useState(VoiceStatus.ENROLLED_AND_ENROLLING);
  const [exportLoading, setExportLoading] = useState(false);

  const getPayload = () => {
    const payload = {};
    if (cif && cif !== "") {
      payload.cif = cif.trim();
    }
    if (phone && phone !== "") {
      payload.phone = phone.trim();
    }
    if (agentName && agentName !== "") {
      payload.agent = agentName.trim();
    }
    if (citizenId && citizenId !== "") {
      payload.citizen_id = citizenId.trim();
    }
    if (dates && dates.length) {
      const startDate = dates[0].format("YYYY-MM-DD HH:mm");
      const endDate = dates[1].format("YYYY-MM-DD HH:mm");
      payload.start_date = startDate;
      payload.end_date = endDate;
    }
    if (selectedEnrollChannel && selectedEnrollChannel !== "all") {
      payload.enroll_type = selectedEnrollChannel;
    }
    if (status !== null && status !== "all") {
      payload.status = status;
    }

    return payload;
  };

  const handleSearch = () => {
    const payload = getPayload();
    onSearch(payload);
  };

  const handleExport = () => {
    const payload = getPayload();
    setExportLoading(true);
    onExportReport(payload, () => {
      setExportLoading(false);
    });
  };

  return (
    <Card>
      <span className="text_title">{t("VOICE_FILTER")}</span>
      <div className={styles.main}>
        <div className={styles.form_control}>
          <div className="label">{t("CIF")}</div>
          <Input
            placeholder={t("CIF")}
            className="text"
            value={cif}
            onChange={(e) => setCif(e.target.value)}
            allowClear
          />
        </div>

        <div className={styles.form_control}>
          <div className="label">{t("PHONE")}</div>
          <Input
            placeholder={t("PHONE")}
            className="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            allowClear
          />
        </div>

        <div className={styles.form_control}>
          <div className="label">{t("AGENT_ENROLL")}</div>
          <Input
            placeholder={t("AGENT_ENROLL")}
            className="text"
            value={agentName}
            onChange={(e) => setAgentName(e.target.value)}
            allowClear
          />
        </div>

        <div className={styles.form_control}>
          <div className="label">{t("CMND")}</div>
          <Input
            placeholder={t("CMND")}
            className="text"
            value={citizenId}
            onChange={(e) => setCitizenId(e.target.value)}
            allowClear
          />
        </div>

        <div className={styles.form_control}>
          <span className="label">{t("ENROLL_TIME")}</span>
          <TimeRange dates={dates} onChangeDates={setDates} border />
        </div>

        <div className={styles.row}>
          <div className={styles.form_control} style={{ width: "50%" }}>
            <span className="label">{t("ENROLL_CHANNEL")}</span>
            <Select
              placeholder={t("ENROLL_CHANNEL")}
              className="text"
              value={selectedEnrollChannel}
              onChange={setSelectedEnrollChannel}
              allowClear
            >
              <Option value="all" className="text">
                {t("ALL")}
              </Option>
              <Option value="call_center" className="text">
                {t("AGENT")}
              </Option>
              <Option value="voicebot" className="text">
                {t("VOICEBOT")}
              </Option>
              <Option value="ivr" className="text">
                {t("IVR")}
              </Option>
            </Select>
          </div>

          <div className={styles.form_control} style={{ width: "50%" }}>
            <div className="label">{t("STATUS")}</div>
            <Select
              value={status}
              onChange={setStatus}
              placeholder={t("STATUS")}
              allowClear
              className="text"
            >
              <Option value="all" className="text">
                {t("ALL")}
              </Option>
              <Option
                value={VoiceStatus.ENROLLED_AND_ENROLLING}
                className="text"
              >
                {t("ENROLLED_AND_ENROLLING_STATUS")}
              </Option>

              <Option value={VoiceStatus.WAIT_ENROLL} className="text">
                {t("WAIT_ENROLL")}
              </Option>
              <Option value={VoiceStatus.ENROLLED} className="text">
                {t("ENROLLED")}
              </Option>
              <Option
                value={VoiceStatus.NOT_ENOUGH_NET_SPEECH}
                className="text"
              >
                {t("NOT_ENOUGH_NET_SPEECH")}
              </Option>
              <Option value={VoiceStatus.NOT_ENOUGH_QUALITY} className="text">
                {t("NOT_ENOUGH_QUALITY")}
              </Option>
              <Option
                value={VoiceStatus.MULTIPLE_PEOPLE_IN_CALL}
                className="text"
              >
                {t("MULTIPLE_PEOPLE_IN_CALL")}
              </Option>
              <Option value={VoiceStatus.REMOVE_VOICE} className="text">
                {t("REMOVE_VOICE")}
              </Option>
            </Select>
          </div>
        </div>
      </div>

      <div className={styles.action_container}>
        <Button
          onClick={handleExport}
          icon={<FileExcelOutlined />}
          loading={exportLoading}
        >
          {t("EXPORT_REPORT")}
        </Button>
        <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
          {t("SEARCH")}
        </Button>
      </div>
    </Card>
  );
}

export default Filter;

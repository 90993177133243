import React from "react";
import styles from "./style.module.css";

function Card({ children, noPadding = false }) {
  return (
    <div className={styles.container} style={noPadding ? { padding: 0 } : null}>
      {children}
    </div>
  );
}

export default Card;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Table, Tag, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import styles from "./RoleList.module.css";

import EditPermission from "./EditPermission";
import {
  showConfirmPopup,
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";
import userApi from "apis/user";
import Card from "components/ui/Card";

function RoleList({ getRoles, loading }) {
  const { t } = useTranslation();
  const roles = useSelector((state) => state.user.roles);
  const [visibleEditRole, setVisibleEditRole] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [visibleCreateRole, setVisibleCreateRole] = useState(false);

  const columns = [
    {
      title: t("NAME"),
      dataIndex: "role",
      key: "role",
      render: (name) => {
        return <span className={styles.text}>{name}</span>;
      },
    },
    {
      title: t("DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      render: (description) => {
        return <span className={styles.text}>{description}</span>;
      },
    },
    {
      title: t("PERMISSIONS"),
      dataIndex: "permissions",
      key: "permissions",
      render: (permissions, row) => {
        return (
          <div className={styles.permissions_container}>
            {row.name === "admin" && <Tag color="geekblue">all</Tag>}
            {permissions.map((item, idx) => (
              <Tag key={idx} color="geekblue">
                {item}
              </Tag>
            ))}
            {permissions.length === 0 && row.name !== "admin" && (
              <span className={styles.text}>--</span>
            )}
          </div>
        );
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 200,
      render: (key, row) => {
        return (
          <div className={styles.action_container}>
            <Button
              danger
              type="link"
              disabled={row.name === "admin"}
              onClick={() => {
                handleDeleteRole(row.role);
              }}
            >
              {t("DELETE")}
            </Button>

            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleEditRole(true);
                setSelectedData(row);
              }}
              disabled={row.name === "admin"}
            >
              {t("EDIT")}
            </Button>
          </div>
        );
      },
    },
  ];

  const handleDeleteRole = (roleName) => {
    showConfirmPopup(
      `${t("CONFIRM_DELETE_ROLE")} "${roleName}"?`,
      () => {
        userApi
          .deleteRole(roleName)
          .then((resp) => {
            showSuccessNotification(t("DELETE_ROLE_SUCCESS"));
            getRoles();
          })
          .catch((err) => {
            showErrorNotification(t(err?.detail));
          });
      },
      t("YES"),
      t("NO")
    );
  };

  return (
    <Card>
      <Row justify="space-between">
        <span className="text-small-11">
          {t("TOTAL_RECORD")} {roles.length}
        </span>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setVisibleCreateRole(true)}
        >
          {t("CREATE_ROLE")}
        </Button>
      </Row>
      <Table
        columns={columns}
        dataSource={roles}
        loading={loading}
        bordered={false}
      />
      {visibleEditRole && selectedData && (
        <EditPermission
          visible={visibleEditRole}
          setVisible={setVisibleEditRole}
          data={selectedData}
          setData={setSelectedData}
          onSaveDone={() => {
            getRoles();
          }}
        />
      )}
      {visibleCreateRole && (
        <EditPermission
          visible={visibleCreateRole}
          setVisible={setVisibleCreateRole}
          onSaveDone={() => {
            getRoles();
          }}
        />
      )}
    </Card>
  );
}

export default RoleList;

import React from "react";
import { useTranslation } from "react-i18next";
import Status from "components/ui/Status";
import Card from "components/ui/Card";

function VerifyInfo({ agentId, cif, phone, setCallId, agentName, ucid }) {
  const { t } = useTranslation();
  return (
    <Card>
      <span className="text_title">{t("VERIFY_INFO")}</span>
      <Status
        agentId={agentId}
        cif={cif}
        phone={phone}
        type="verify"
        setCallId={setCallId}
        agentName={agentName}
        ucid={ucid}
      />
    </Card>
  );
}

export default VerifyInfo;

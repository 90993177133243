export const Status = {
  PROCESSING: -1,
  FAILED: 0,
  SUCCESS: 1,
};

export const getStatusMessage = (step, value) => {
  if (step === "collecting_voice") {
    if (value === Status.PROCESSING) {
      return "COLLECTING_VOICE";
    } else {
      return "COLLECTED_VOICE";
    }
  } else if (step === "check_quality") {
    if (value === Status.PROCESSING) {
      return "CHECKING_QUALITY";
    } else {
      return "CHECKED_QUALITY";
    }
  } else if (step === "ready") {
    return "READY";
  }
};

export const EnrollStep = {
  COLLECTING_VOICE: "collecting_voice",
  CHECKING_QUALITY: "check_quality",
  READY: "ready",
  ENROLLING: "enrolled",
};

export const ProcessType = {
  ENROLL: "enroll",
  VERIFY: "verify",
};

export const Env = {
  CLEAN: "1",
  NOISY: "0",
};

export const Speaking = {
  YES: "1",
  NO: "0",
};

import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import authApi from "apis/authentication";
import { TOKEN_KEY } from "constants/global";
import Loading from "components/ui/Loading";
import { showErrorNotification } from "components/ui/Notification";
import { useTranslation } from "react-i18next";

const authContext = createContext();

export function useAuthProvider() {
  return useContext(authContext);
}

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser === null) {
      getUser();
    }
  }, [currentUser]);

  const getUser = () => {
    setLoading(true);
    authApi
      .getUser()
      .then((data) => {
        setLoading(false);
        const { user_id, username, menu, role } = data;
        setCurrentUser({
          username,
          user_id,
          menu,
          role,
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 403) {
          console.log(location.pathname);
          if (location.pathname !== "/status") {
            navigate("/login", { replace: true });
          }
        }
      });
  };

  const login = async (
    username,
    password,
    complete,
    isRedirectToHomePage = true
  ) => {
    try {
      localStorage.removeItem(TOKEN_KEY);
      const data = await authApi.login(username, password);
      if (data) {
        const { user_id, menu, role, token } = data;
        setCurrentUser({
          username,
          user_id,
          role,
          menu,
        });
        localStorage.setItem(TOKEN_KEY, token);
        if (isRedirectToHomePage) {
          navigate("/");
        }

        complete();
      }
    } catch (err) {
      complete();
      showErrorNotification(t(err?.detail));
    }
  };

  const logout = (isRedirectToLogin = true) => {
    authApi
      .logout()
      .then((resp) => {
        localStorage.removeItem(TOKEN_KEY);
        setCurrentUser(null);
        if (isRedirectToLogin) {
          navigate("/login", { replace: true });
        }
      })
      .catch((err) => {
        showErrorNotification(err?.detail);
      });
  };

  const value = useMemo(
    () => ({
      login,
      logout,
      currentUser,
      loading,
    }),
    [currentUser]
  );

  return (
    <authContext.Provider value={value}>
      {loading ? <Loading /> : <>{children}</>}
    </authContext.Provider>
  );
}

import React, { useState } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { Row, Col } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import Card from "components/ui/Card";
import VerifyByDay from "./components/VerifyByDay";
import VoiceManagement from "./components/VoiceManagement";
import TimeRange from "components/ui/TimeRange";

function Dashboard() {
  const { t } = useTranslation();
  const [dates, setDates] = useState([moment().subtract(7, "days"), moment()]);
  return (
    <PerfectScrollBar className={styles.container}>
      <Card>
        <Row align="middle">
          <Col span={16}>
            <span className="text_title">{t("FIND_BY_DAY")}</span>
          </Col>
          <Col span={8}>
            <TimeRange dates={dates} onChangeDates={setDates} border />
          </Col>
        </Row>
      </Card>
      <Card noPadding={true}>
        <VerifyByDay dates={dates} />
      </Card>
      <Card noPadding={true}>
        <VoiceManagement dates={dates} />
      </Card>
    </PerfectScrollBar>
  );
}

export default Dashboard;

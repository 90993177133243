import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./globalSlice";
import dashboardSlice from "./dashboardSlice";
import requestSlice from "./requestSlice";
import verifySlice from "./verifySlice";
import userSlice from "./userSlice";

const store = configureStore({
  reducer: {
    global: globalSlice,
    dashboard: dashboardSlice,
    request: requestSlice,
    verify: verifySlice,
    user: userSlice,
  },
});

export default store;

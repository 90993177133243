import { TOKEN_KEY } from "constants/global";

export function getToken() {
  const token = localStorage.getItem(TOKEN_KEY);
  return token;
}

export function getCallingUrl() {
  let url = `${process.env.REACT_APP_LIVE_URL}/api/v1/status/calling`;
  return url;
}

export function getStatusUrl(agentId, cif, phone, agentName, ucid) {
  let url = `${process.env.REACT_APP_LIVE_URL}/api/v1/status/agent/${agentId}/name/${agentName}/phone/${phone}/cif/${cif}/ucid/${ucid}`;
  return url;
}

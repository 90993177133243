import { useEffect } from "react";

function useOutside(containerRef, excludes_cls, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (
        !containerRef.current ||
        containerRef.current.contains(event.target)
      ) {
        return;
      }

      if (
        excludes_cls &&
        excludes_cls.length > 0 &&
        excludes_cls.includes(event.target.className)
      ) {
        return;
      }
      handler();
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [containerRef, handler]);
}

export default useOutside;

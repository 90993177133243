import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import Card from "components/ui/Card";

import styles from "./History.module.css";
import customerApi from "apis/customer";
import VerifyResultTable from "components/ui/VerifyResultTable";

function History({ cif, phone }) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHistories(page, pageSize);
  }, []);

  const getHistories = (currentPage, currentPageSize) => {
    setLoading(true);
    customerApi
      .getCustomerHistories(cif, phone, currentPage, currentPageSize)
      .then((resp) => {
        setLoading(false);
        const tempData = resp.histories.map((item, idx) => {
          item.key = idx;
          return item;
        });
        setDataSource(tempData);
        setTotal(resp.total);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChangePage = (currentPage, currentSize) => {
    setPage(currentPage);
    setPageSize(currentSize);
    getHistories(currentPage, currentSize);
  };

  return (
    <Card>
      <span className="text_title">{t("VERIFY_HISTORY_OF_CUSTOMER")}</span>
      <VerifyResultTable
        dataSource={dataSource}
        loading={loading}
        onRow={() => {}}
      />
      <div className={styles.page_container}>
        <Pagination
          total={total}
          current={page}
          pageSize={pageSize}
          onChange={handleChangePage}
        />
      </div>
    </Card>
  );
}

export default History;

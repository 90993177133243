import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  roles: [],
  permissions: [],
};
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
});

export const userAction = userSlice.actions;

export default userSlice.reducer;

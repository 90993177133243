import React, { useEffect, useRef } from "react";
import { TOKEN_KEY } from "constants/global";
import axios from "axios";

function SingleAudioPlayer({ filePath, isPlay = false }) {
  const audioRef = useRef();

  useEffect(() => {
    loadAudioData();
  }, []);

  const loadAudioData = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    var config = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    };
    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/customers/voice_print/${[filePath]}/play`;

    axios
      .get(url, config)
      .then(function (response) {
        var audioBlob = response.data;
        var url = URL.createObjectURL(audioBlob);

        if (audioRef.current) {
          audioRef.current.src = url;
          audioRef.current.currentTime = 0;
          if (isPlay) {
            audioRef.current.play();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <audio ref={audioRef} controls style={{ width: "100%" }}>
      <source type="audio/wav" src=""></source>
    </audio>
  );
}

export default SingleAudioPlayer;

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Button, Progress } from "antd";
import {
  CaretRightOutlined,
  PauseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import styles from "./style.module.css";
import { TOKEN_KEY } from "constants/global";

function AudioPlayer({ urls, duration }) {
  const audioRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [percent, setPercent] = useState(0);
  const [currentAudioIdx, setCurrentAudioIdx] = useState(0);
  const [loadedAudio, setLoadedAudio] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (urls.length > 0 && audioRef.current !== null) {
      const url = urls[0];
      if (!audioRef.current.src || audioRef.current.src === "") {
        loadAudioDataFromUrl(url);
      }
    }
  }, []);

  const loadAudioDataFromUrl = (audioUrl) => {
    const token = localStorage.getItem(TOKEN_KEY);
    var config = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    };

    setLoading(true);
    axios
      .get(audioUrl, config)
      .then(function (response) {
        var audioBlob = response.data;
        var url = URL.createObjectURL(audioBlob);
        setLoading(false);
        if (audioRef.current) {
          audioRef.current.src = url;
          audioRef.current.currentTime = 0;
          audioRef.current.play();
          setIsPlay(true);

          const tmpLoaded = [...loadedAudio];
          if (tmpLoaded.length < urls.length) {
            tmpLoaded.push(url);
            setLoadedAudio(tmpLoaded);
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const loadAudioFromCache = (index, autoPlay = true) => {
    if (index < loadedAudio.length) {
      const url = loadedAudio[index];
      audioRef.current.src = url;
      audioRef.current.currentTime = 0;
      if (autoPlay) {
        audioRef.current.play();
        setIsPlay(true);
      } else {
        audioRef.current.pause();
        setIsPlay(false);
      }
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      if (isPlay) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlay, audioRef]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setCurrentTime(audioRef.current.currentTime + currentTime);
        if (urls.length > 0) {
          let index = currentAudioIdx + 1;
          if (index >= urls.length) {
            setCurrentTime(0);
            setCurrentAudioIdx(0);
            setPercent(0);
            loadAudioFromCache(0, false);
          } else {
            const url = urls[index];
            if (index < loadedAudio.length) {
              loadAudioFromCache(index);
            } else {
              loadAudioDataFromUrl(url);
            }

            setCurrentAudioIdx(index);
          }
        }
      };
    }

    return () => {};
  });

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading_container}>
          <LoadingOutlined />
        </div>
      ) : (
        <Button
          shape="circle"
          type="text"
          icon={isPlay ? <PauseOutlined /> : <CaretRightOutlined />}
          onClick={() => {
            setIsPlay(!isPlay);
          }}
        />
      )}
      <Progress
        trailColor="#bdc3c7"
        showInfo={false}
        percent={percent}
        style={{ marginTop: 5 }}
      />
      <audio
        ref={audioRef}
        onTimeUpdate={() => {
          const audioTime = audioRef.current.currentTime;
          if (duration > 0) {
            setPercent(((currentTime + audioTime) * 100) / duration);
          }
        }}
      />
    </div>
  );
}

export default AudioPlayer;

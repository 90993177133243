export const VoiceStatus = {
  ENROLLED_AND_ENROLLING: -2,
  WAIT_ENROLL: 0,
  ENROLLED: 1,
  NOT_ENOUGH_NET_SPEECH: 2,
  NOT_ENOUGH_QUALITY: 3,
  MULTIPLE_PEOPLE_IN_CALL: 4,
  REMOVE_VOICE: -1,
};

export const getStatus = (status) => {
  if (status === VoiceStatus.WAIT_ENROLL) {
    return "WAIT_ENROLL";
  } else if (status === VoiceStatus.ENROLLED) {
    return "ENROLLED";
  } else if (status === VoiceStatus.NOT_ENOUGH_NET_SPEECH) {
    return "NOT_ENOUGH_NET_SPEECH";
  } else if (status === VoiceStatus.NOT_ENOUGH_QUALITY) {
    return "NOT_ENOUGH_QUALITY";
  } else if (status === VoiceStatus.MULTIPLE_PEOPLE_IN_CALL) {
    return "MULTIPLE_PEOPLE_IN_CALL";
  } else if (status === VoiceStatus.REMOVE_VOICE) {
    return "REMOVED_VOICE";
  }
};

import React, { useRef, useState } from "react";
// import Downloader from "js-file-download";
import Scroll from "react-perfect-scrollbar";
import Filter from "./components/Filter";
import HistoryList from "./components/HistoryList";
import styles from "./style.module.css";
import customerApi from "apis/customer";
import { showErrorNotification } from "components/ui/Notification";

function History() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [currentPayload, setCurrentPayload] = useState({});
  const scrollRef = useRef();

  const getHistories = (payload, currentPage, currentPageSize) => {
    let reqPage, reqSize;
    if (currentPage !== undefined && currentPageSize !== undefined) {
      reqPage = currentPage;
      reqSize = currentPageSize;
    } else {
      reqPage = page;
      reqSize = pageSize;
    }
    let reqData = {};
    if (payload !== null) {
      setCurrentPayload(payload);
      reqData = { ...payload };
    } else {
      reqData = { ...currentPayload };
    }
    setLoading(true);
    customerApi
      .getHistories(reqData, reqPage, reqSize)
      .then((resp) => {
        setLoading(false);
        setTotal(resp.total);
        const tmpData = resp.histories.map((item, idx) => {
          item.key = idx;
          return item;
        });
        setDataSource(tmpData);
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };

  const exportHistory = (payload, completed) => {
    customerApi.exportHistory(payload, completed);
  };

  const handleChangePage = (currentPage, currentSize) => {
    setPage(currentPage);
    setPageSize(currentSize);
    getHistories(null, currentPage, currentSize);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  return (
    <Scroll
      className={styles.container}
      containerRef={(el) => {
        scrollRef.current = el;
      }}
    >
      <Filter onSearch={getHistories} onExportReport={exportHistory} />
      <HistoryList
        total={total}
        page={page}
        pageSize={pageSize}
        loading={loading}
        dataSource={dataSource}
        onChangePage={handleChangePage}
      />
    </Scroll>
  );
}

export default History;

import React from "react";
import { useTranslation } from "react-i18next";
import Status from "components/ui/Status";
import Card from "components/ui/Card";

function VoiceInfo({
  agentId,
  cif,
  phone,
  setEnrolledInfo,
  setCallId,
  agentName,
  ucid,
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <span className="text_title">{t("VOICE_INFO")}</span>
      <Status
        agentId={agentId}
        cif={cif}
        phone={phone}
        onSave={setEnrolledInfo}
        type="enroll"
        setCallId={setCallId}
        agentName={agentName}
        ucid={ucid}
      />
    </Card>
  );
}

export default VoiceInfo;

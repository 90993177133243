import React from "react";
import { Spin } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./Step.module.css";
import { useTranslation } from "react-i18next";
import { Status, getStatusMessage } from "../status";

function Step({ step, value, idx, numberStep }) {
  const { t } = useTranslation();
  return (
    <div className={styles.row}>
      <div className={styles.icon_container}>
        {value === Status.SUCCESS && <CheckOutlined className="text_success" />}
        {value === Status.FAILED && <CloseOutlined className="text_error" />}

        {value === Status.PROCESSING && (
          <Spin size="small" style={{ marginTop: 5 }} />
        )}
      </div>
      <div>
        <span className="text">
          {t(getStatusMessage(step, value))} ({idx + 1}/{numberStep})
        </span>
      </div>
    </div>
  );
}

export default Step;

import React, { useState, useEffect } from "react";
import { Spin, Divider } from "antd";
import { useTranslation } from "react-i18next";
import ReactECharts from "echarts-for-react";
import styles from "./VoiceManagement.module.css";
import dashboardApi from "apis/dashboard";
import { convertMomentToString } from "utils/datetime";

function VoiceManagement({ dates }) {
  const { t } = useTranslation();
  // const [dates, setDates] = useState([moment().subtract(7, "days"), moment()]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className="text_title">{t("VOICE_MANAGEMENT")}</span>
      </div>
      <div className={styles.main}>
        <EnrollStatistic dates={dates} />
        <Divider />
        <VerifyStatistic dates={dates} />
      </div>
    </div>
  );
}

function VerifyStatistic({ dates }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    getVerifyStatistic();
  }, [dates]);

  const getVerifyStatistic = () => {
    if (!dates || dates.length < 1) {
      return;
    }
    const start = convertMomentToString(dates[0]);
    const end = convertMomentToString(dates[1]);
    setLoading(true);
    dashboardApi
      .getVerifyStatistic(start, end)
      .then((resp) => {
        setLoading(false);
        setData(resp);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {},
    yAxis: {
      type: "value",
    },
    xAxis: {
      type: "category",
      data: data?.days,
    },
    series: [
      {
        name: ">= 85%",
        type: "bar",
        stack: "total",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.passed,
        itemStyle: {
          color: "#4e79a7",
        },
      },
      {
        name: "< 85%",
        type: "bar",
        stack: "total",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.failed,
        itemStyle: {
          color: "#f28d2c",
        },
      },
    ],
  };

  return (
    <Chart title={t("VERIFY_STATISTIC")} options={options} loading={loading} />
  );
}

const EnrollStatistic = ({ dates }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    getEnrollStatistic();
  }, [dates]);

  const getEnrollStatistic = () => {
    if (!dates || dates.length < 1) {
      return;
    }
    const start = convertMomentToString(dates[0]);
    const end = convertMomentToString(dates[1]);
    setLoading(true);
    dashboardApi
      .getEnrollStatistic(start, end)
      .then((resp) => {
        setLoading(false);
        setData(resp);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {},
    yAxis: {
      type: "value",
    },
    xAxis: {
      type: "category",
      data: data?.days,
    },
    series: [
      {
        name: t("ENROLL_SUCCESS"),
        type: "bar",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.completed,
        itemStyle: {
          color: "#4e79a7",
        },
      },
      {
        name: t("ENROLL_PENDING"),
        type: "bar",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.pending,
        itemStyle: {
          color: "#f28d2c",
        },
      },
      {
        name: t("NOT_ENOUGH_SPEECH"),
        type: "bar",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.not_enough_speech,
        itemStyle: {
          color: "#9c745f",
        },
      },
      {
        name: t("NOT_ENOUGH_QUALITY"),
        type: "bar",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.not_enough_quality,
        itemStyle: {
          color: "#76b8b2",
        },
      },
      {
        name: t("2_PERSON_IN_CALL"),
        type: "bar",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: data?.multiple_in_call,
        itemStyle: {
          color: "#7f7f7f",
        },
      },
    ],
  };

  return (
    <Chart title={t("NEW_ENROLLMENT")} options={options} loading={loading} />
  );
};

function Chart({ title, options, loading }) {
  return (
    <div className={styles.statistic_container}>
      <h4>{title}</h4>
      <div className={styles.statistic_main}>
        {loading ? (
          <Spin />
        ) : (
          <ReactECharts
            option={options}
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </div>
    </div>
  );
}

export default VoiceManagement;

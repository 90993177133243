import React from "react";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./EnrollResult.module.css";

function EnrollResult({ isEnrolled, countSentence }) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h3>{t("ENROLL_RESULT")}</h3>
      <h4 className="text" style={{ marginTop: "1rem", marginLeft: "0.8rem" }}>
        {t("SENTENCE_COUNT")}: {countSentence}
      </h4>
      <Checkbox checked={isEnrolled === "1"}>{t("ENROLLED")}</Checkbox>
    </div>
  );
}

export default EnrollResult;

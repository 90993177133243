import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./style.module.css";
import { Form, Button, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useAuthProvider } from "contexts/authContext";

function IframeLogin({}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const { login } = useAuthProvider();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    login(
      values.username,
      values.password,
      () => {
        setLoading(false);
        redirectToIframe();
      },
      false
    );
  };

  const redirectToIframe = () => {
    const agentId = searchParams.get("agentId");
    const cif = searchParams.get("cif");
    const phone = searchParams.get("phone");
    const phoneType = searchParams.get("phoneType");
    const username = searchParams.get("username");
    const citizenId = searchParams.get("citizenId");
    const clientType = searchParams.get("clientType");
    const ucid = searchParams.get("ucid");

    const url = `/status?agentId=${agentId}&username=${username}&citizenId=${citizenId}&cif=${cif}&phone=${phone}&phoneType=${phoneType}&clientType=${clientType}&ucid=${ucid}`;
    navigate(url);
  };

  return (
    <div className={styles.container}>
      <Form
        form={form}
        name="login"
        initialValues={{
          remember: true,
        }}
        onFinish={handleSubmit}
        layout="vertical"
        className={styles.form}
      >
        <Form.Item
          name="username"
          label={t("USERNAME")}
          rules={[
            {
              required: true,
              message: t("REQUIRED_USERNAME"),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className={styles.icon} />}
            placeholder={t("USERNAME")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("PASSWORD")}
          rules={[
            {
              required: true,
              message: t("REQUIRED_PASSWORD"),
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className={styles.icon} />}
            placeholder={t("PASSWORD")}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {t("LOGIN")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default IframeLogin;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Table, Button, Pagination } from "antd";
import { UserOutlined, FormOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import styles from "./UserList.module.css";
import userApi from "apis/user";
import { userAction } from "store/userSlice";
import { DATE_FORMAT } from "constants/global";
import UserEdit from "./UserEdit";
import FilterUser from "./UserFilter";
import Card from "components/ui/Card";

function UserList({ scrollTop }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleEditRole, setVisibleEditRole] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [filterObj, setFilterObj] = useState({});

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const columns = [
    {
      title: t("USERNAME"),
      dataIndex: "username",
      key: "username",
      render: (name) => {
        return (
          <div className={styles.username_container}>
            <Avatar
              shape="square"
              size="small"
              icon={<UserOutlined />}
              style={{
                backgroundColor: "#34495e",
                verticalAlign: "middle",
              }}
            />
            <span className="text">{name}</span>
          </div>
        );
      },
    },

    {
      title: t("AGENT_ID"),
      dataIndex: "agent_id",
      key: "agent_id",
      render: (agent_id) => {
        return <span className="text">{agent_id}</span>;
      },
    },
    {
      title: t("ROLE"),
      dataIndex: "role",
      key: "role",
      render: (role) => {
        return <span className="text">{role}</span>;
      },
    },
    {
      title: t("UPDATED_DATE"),
      dataIndex: "updated",
      key: "updated",
      render: (date) => {
        const updatedDate = moment(date).format(DATE_FORMAT);
        return <span className="text">{updatedDate}</span>;
      },
    },
    {
      title: t("ACTION"),
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 100,
      render: (key, row) => {
        return (
          <span className={styles.action_container}>
            <Button
              type="link"
              size="small"
              onClick={() => {
                selectUser(key);
                setVisibleEditRole(true);
              }}
              // disabled={row.role === "admin"}
            >
              {t("EDIT")}
            </Button>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    getUsers(null, page, pageSize);
  }, []);

  const handleChangePage = (currenPage, currentSize) => {
    setPage(currenPage);
    setPageSize(currentSize);
    getUsers(null, currenPage, currentSize);
    scrollTop();
  };

  const selectUser = (selectedUserKey) => {
    setVisibleEditRole(true);
    const tmp = [...selectedRowKeys];
    if (
      selectedUserKey !== null &&
      selectedUserKey !== undefined &&
      selectedRowKeys !== undefined &&
      !tmp.includes(selectedUserKey)
    ) {
      tmp.push(selectedUserKey);
      setSelectedRowKeys(tmp);
    }
    const selectedUsers = users.filter((user) => tmp.includes(user.username));
    setSelectedData(selectedUsers);
  };

  const getUsers = (payload, currentPage, currentSize) => {
    if (!currentPage && !currentSize) {
      currentPage = page;
      currentSize = pageSize;
    }
    let reqData = {};
    if (payload !== null) {
      setFilterObj(payload);
      reqData = { ...payload };
    } else {
      reqData = { ...filterObj };
    }

    userApi.getUsers(reqData, currentPage, currentSize).then((resp) => {
      if (resp && resp.users) {
        const tmpUsers = resp.users.map((item) => {
          item.key = item.username;
          return item;
        });
        dispatch(userAction.setUsers(tmpUsers));
        setTotal(resp.total);
      }
    });
  };

  return (
    <div className={styles.container}>
      <FilterUser onSearch={getUsers} />

      <Card>
        <span className="text-small-11">
          {t("TOTAL_RECORD")} {total}
        </span>
        <div className={styles.header}>
          <div className={styles.selection_container}>
            <Button
              type="primary"
              disabled={selectedRowKeys.length === 0}
              icon={<FormOutlined />}
              onClick={() => {
                selectUser();
              }}
            >
              {t("EDIT")}
            </Button>

            {selectedRowKeys.length > 0 && (
              <span className={styles.text}>
                {t("SELECTED")} {selectedRowKeys.length}{" "}
                {t("USERS").toLocaleLowerCase()}
              </span>
            )}
          </div>
        </div>
        <Table
          dataSource={users}
          columns={columns}
          bordered={false}
          rowSelection={rowSelection}
          pagination={false}
        />
        <div className={styles.pagination_container}>
          <Pagination
            total={total}
            current={page}
            pageSize={pageSize}
            onChange={handleChangePage}
          />
        </div>
      </Card>

      {visibleEditRole && selectedData.length > 0 && (
        <UserEdit
          visible={visibleEditRole}
          setVisible={setVisibleEditRole}
          users={selectedData}
          setUsers={setSelectedData}
          setSelectedRowKeys={setSelectedRowKeys}
          onComplete={getUsers}
        />
      )}
    </div>
  );
}

export default UserList;

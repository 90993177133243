import React from "react";
import { MehOutlined } from "@ant-design/icons";
import { Result } from "antd";
import styles from "./Error.module.css";

function Error({ title, desc, status = "warning" }) {
  return (
    <div className={styles.container}>
      <Result
        status={status}
        title={title}
        icon={<MehOutlined />}
        extra={<span className={styles.example_text}>{desc}</span>}
      />
    </div>
  );
}

export default Error;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import { FieldTimeOutlined } from "@ant-design/icons";
import styles from "./style.module.css";
import Extension from "./Extension";

const { RangePicker } = DatePicker;
const format = "YYYY-MM-DD HH:mm:ss";
dayjs.extend(customParseFormat);

function TimeRange({ dates, onChangeDates, border }) {
  const { t } = useTranslation();
  const [visibleExtent, setVisibleExtent] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    if (dates) {
      const start = dayjs(dates[0].format(format), format);
      const end = dayjs(dates[1].format(format), format);
      setCurrentDate([start, end]);
    } else {
      setCurrentDate(null);
    }
  }, [dates]);

  const setDates = (values) => {
    setCurrentDate(values);
    if (!values) {
      onChangeDates(null);
    } else {
      const start = moment(values[0].format());
      const end = moment(values[1].format());
      onChangeDates([start, end]);
    }
  };

  return (
    <div
      className={styles.container}
      style={border ? { border: "1px solid #d9d9d9" } : null}
    >
      <div
        className={styles.opt_container}
        onClick={() => {
          setVisibleExtent(true);
        }}
      >
        <FieldTimeOutlined />
      </div>

      <div className={styles.range_container}>
        <RangePicker
          value={currentDate}
          onChange={setDates}
          className={styles.full}
          bordered={false}
          format="YYYY-MM-DD HH:mm"
          showTime
          placeholder={[t("START_DATE"), t("END_DATE")]}
        />
      </div>

      {visibleExtent && (
        <Extension setVisible={setVisibleExtent} setDates={setDates} />
      )}
    </div>
  );
}

export default TimeRange;

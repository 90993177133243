import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import ReactJson from "react-json-view";
import Scroll from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import styles from "./Log.module.css";
import { TOKEN_KEY } from "constants/global";
import SingleAudioPlayer from "components/ui/AudioPlayer/SingleAudioPlayer";

function Log({ visible, setVisible, data, setData }) {
  const { t } = useTranslation();
  const [audioPath, setAudioPath] = useState(null);

  useEffect(() => {
    if (data.callId) {
      const path = `audio/call_center/call_${data?.callId}/audio_full.wav`;
      setAudioPath(path);
    }
  }, []);

  const handleClose = () => {
    setVisible(false);
    setData(null);
  };

  return (
    <Drawer
      title={t("LOG_DETAIL")}
      open={visible}
      onClose={handleClose}
      width={500}
    >
      <Scroll className={styles.container}>
        {audioPath !== null && <SingleAudioPlayer filePath={audioPath} />}

        <div className={styles.json_container}>
          <ReactJson src={data.log} style={{ marginTop: "1.6rem" }} />
        </div>
      </Scroll>
    </Drawer>
  );
}

export default Log;

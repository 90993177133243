import React, { useState, useEffect } from "react";
import Scroll from "react-perfect-scrollbar";
import styles from "./index.module.css";
import Filter from "./Filter";
import callLogApi from "apis/callLog";
import { showErrorNotification } from "components/ui/Notification";
import AuthorList from "./AuthorList";

function AuthorLog() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPayload, setCurrentPayload] = useState({});

  useEffect(() => {
    handleSearch(null, page, pageSize);
  }, []);

  const handleChangePage = (currentPage, currentPageSize) => {
    setPage(currentPage);
    setPageSize(currentPageSize);
    handleSearch(null, currentPage, currentPageSize);
  };

  const handleSearch = (payload, currentPage, currentPageSize) => {
    let reqPage, reqSize;
    if (currentPage && currentPageSize) {
      reqPage = currentPage;
      reqSize = currentPageSize;
    } else {
      reqPage = 1;
      reqSize = 20;
      setPage(reqPage);
      setPageSize(reqSize);
    }
    let reqData = {};
    if (payload !== null) {
      setCurrentPayload(payload);
      reqData = { ...payload };
    } else {
      reqData = { ...currentPayload };
    }
    setLoading(true);
    callLogApi
      .getAuthorLog(reqData, reqPage, reqSize)
      .then((resp) => {
        setLoading(false);
        setTotal(resp.total);
        setDataSource(resp.logs);
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };
  return (
    <Scroll className={styles.container}>
      <Filter onSearch={handleSearch} />
      <AuthorList
        dataSource={dataSource}
        loading={loading}
        page={page}
        pageSize={pageSize}
        onChangePage={handleChangePage}
        total={total}
      />
    </Scroll>
  );
}

export default AuthorLog;

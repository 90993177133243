import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import Status from "components/ui/Status";
import { PhoneType, ClientType } from "constants/global";
import Error from "components/ui/Status/components/Error";

function IframeStatus({ type }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const render = () => {
    const agentId = searchParams.get("agentId");
    const cif = searchParams.get("cif");
    const phone = searchParams.get("phone");
    const phoneType = searchParams.get("phoneType");
    const username = searchParams.get("username");
    const citizenId = searchParams.get("citizenId");
    const clientType = searchParams.get("clientType");
    const ucid = searchParams.get("ucid");

    const phoneTypeValues = [PhoneType.MAIN, PhoneType.SUB, PhoneType.NONE];
    const clientTypeValues = [ClientType.DIN, ClientType.STAFF, ClientType.FIN];

    if (
      !phoneType ||
      !agentId ||
      !cif ||
      !phone ||
      !phoneTypeValues.includes(phoneType) ||
      !citizenId ||
      !username ||
      !clientType ||
      !ucid
    ) {
      return (
        <Error
          title={t("INVALID_CUSTOMER_PARAM")}
          desc={t("CUSTOMER_PARAM_EX")}
          status="error"
        />
      );
    } else if (phoneType && phoneType === PhoneType.NONE) {
      return (
        <Error
          title={t("PHONE_NOT_IN_LIST")}
          desc={t("VOIDEDNA_NOT_PROCESS")}
          status="warning"
        />
      );
    } else if (!clientTypeValues.includes(clientType)) {
      return (
        <Error
          title={t("PHONE_NOT_IN_VERIFY_RANGE")}
          desc={t("VOIDEDNA_NOT_PROCESS")}
          status="warning"
        />
      );
    } else {
      return (
        <Status
          agentId={agentId}
          cif={cif}
          phone={phone}
          citizenId={citizenId}
          type="iframe"
          phoneType={phoneType}
          isLogin={type === "loggedin"}
          agentName={username}
          ucid={ucid}
          clientType={clientType}
        />
      );
    }
  };

  return <div className={styles.container}>{render()}</div>;
}

export default IframeStatus;

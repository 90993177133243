import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Button, Tag, Select, Divider, Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./UserEdit.module.css";
import {
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";
import userApi from "apis/user";

const { Option } = Select;

function EditUser({
  visible,
  setVisible,
  users,
  setUsers,
  setSelectedRowKeys,
  onComplete,
}) {
  const { t } = useTranslation();
  const roles = useSelector((state) => state.user.roles);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const tmpData = users.map((item) => {
      return {
        agent_id: item.agent_id,
        role: item.role,
        username: item.username,
        id: item.id,
      };
    });
    setData(tmpData);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setUsers([]);
    setSelectedRowKeys([]);
  };

  const handleSave = () => {
    const invalid = data.find(
      (item) =>
        !item.agent_id || item.agent_id === "" || !item.role || item.role === ""
    );
    if (invalid) {
      showErrorNotification(t("ERROR_USER_MSG"));
      return;
    }

    setLoading(true);
    const payload = { users: data };
    userApi
      .updateUserRole(payload)
      .then((resp) => {
        setLoading(false);
        showSuccessNotification(t("UPDATE_USER_ROLE_SUCCESS"));
        onComplete();
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };

  return (
    <Drawer
      title={t("EDIT_USER")}
      open={visible}
      onClose={handleClose}
      closable={true}
      width={600}
      bodyStyle={{ padding: 0 }}
      footer={
        <div className={styles.footer}>
          <Button onClick={handleClose}>{t("CANCEL")}</Button>
          <Button type="primary" onClick={handleSave} loading={loading}>
            {t("SAVE")}
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        {users.map((item, idx) => (
          <div className={styles.form_control} key={idx}>
            <Row>
              <Col span={6}>
                <span>{t("USER")}:</span>
              </Col>
              <Col span={18} className={styles.text}>
                <Tag color="blue">{item.username}</Tag>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <span>{t("ROLE")}:</span>
              </Col>
              <Col span={18}>
                <Select
                  placeholder={t("SELECT_ROLE")}
                  defaultValue={item.role}
                  onChange={(value) => {
                    const tmp = [...data];
                    tmp[idx].role = value;
                    setData(tmp);
                  }}
                  className={styles.text}
                  style={{ width: "100%" }}
                >
                  {roles.map((r, i) => (
                    <Option key={i} value={r.role} disabled={r.role == "admin"}>
                      {r.role}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <span>{t("AGENT_ID")}:</span>
              </Col>
              <Col span={18}>
                <Input
                  defaultValue={item.agent_id}
                  onChange={(e) => {
                    const value = e.target.value;
                    const tmp = [...data];
                    tmp[idx].agent_id = value;
                    setData(tmp);
                  }}
                />
              </Col>
            </Row>
            <Divider />
          </div>
        ))}
      </div>
    </Drawer>
  );
}

export default EditUser;

import axiosClient from "./axiosClient";
import Axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { TOKEN_KEY } from "constants/global";
import {
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";

const callLogApi = {
  getCallLog: (payload, page, pageSize) => {
    const url = `/api/v1/logs/calls?page=${page}&size=${pageSize}`;
    return axiosClient.post(url, payload);
  },
  getAuthenLog: (payload, page, pageSize) => {
    const url = `/api/v1/logs/authentication?page=${page}&size=${pageSize}`;
    return axiosClient.post(url, payload);
  },
  getAuthorLog: (payload, page, pageSize) => {
    const url = `/api/v1/logs/authorization?page=${page}&size=${pageSize}`;
    return axiosClient.post(url, payload);
  },
  exportCallLog: (payload, successMessage, errorMessage, completed) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/logs/calls/export`;
    const token = localStorage.getItem(TOKEN_KEY);
    Axios.post(url, payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const now = moment().format("YYYY-MM-DD HH:mm");
        const filename = `calllog_${now}.xlsx`;
        fileDownload(res.data, filename);
        showSuccessNotification(successMessage);
        completed();
      })
      .catch((err) => {
        showErrorNotification(errorMessage);
        completed();
      });
  },
};

export default callLogApi;

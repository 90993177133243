import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Table, Popconfirm, Row } from "antd";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";

import styles from "./SameCustomerList.module.css";
import customerApi from "apis/customer";
import {
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";

function SameCustomerList({ phone, voiceId, citizenId, cif }) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const transition = useTransition(true, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 220, friction: 120 },
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled: record.status === "ENROLLED",
    }),
  };

  useEffect(() => {
    if (phone && citizenId) {
      getCustomerByPhone();
    }
  }, []);

  const getCustomerByPhone = () => {
    setLoading(true);
    customerApi
      .getCustomersSamePhone(cif, phone, citizenId)
      .then((resp) => {
        setLoading(false);
        const tmp = resp.customers.map((item, idx) => {
          item.key = idx;
          return item;
        });
        setDataSource(tmp);
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };

  const columns = [
    {
      title: t("CIF"),
      dataIndex: "cif",
      key: "cif",
      align: "center",
      render: (cif) => {
        return <span className="text">{cif}</span>;
      },
    },
    {
      title: t("CUSTOMER"),
      dataIndex: "name",
      align: "center",
      key: "name",
      render: (name) => {
        return <span className="text">{name}</span>;
      },
    },
    {
      title: t("GENDER"),
      dataIndex: "gender",
      align: "center",
      key: "gender",
      render: (gender) => {
        return <span className="text">{gender}</span>;
      },
    },
    {
      title: t("DOB"),
      dataIndex: "dob",
      align: "center",
      key: "dob",
      render: (dob) => {
        return <span className="text">{moment(dob).format("YYYY-MM-DD")}</span>;
      },
    },
    {
      title: t("CMND"),
      dataIndex: "id_card",
      key: "id_card",
      align: "center",
      render: (id_card) => {
        return <span className="text">{id_card}</span>;
      },
    },
    {
      title: t("PHONE"),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (phone) => {
        return <span className="text">{phone}</span>;
      },
    },
    {
      title: t("EMAIL"),
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (email) => {
        return <span className="text">{email}</span>;
      },
    },
    // {
    //   title: t("TYPE"),
    //   dataIndex: "type",
    //   key: "type",
    //   align: "center",
    //   render: (type) => {
    //     return <span className="text">{type}</span>;
    //   },
    // },
    {
      title: t("VOICEDNA"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        return <span className="text">{t(status)}</span>;
      },
    },
  ];

  const handleConfirmEnroll = (e) => {
    if (selectedRowKeys.length === 0 || !voiceId) {
      return;
    }

    const cifPhonePairs = dataSource
      .filter((_, idx) => selectedRowKeys.includes(idx))
      .map((item) => {
        return {
          cif: item.cif,
          phone: item.phone,
        };
      });

    if (!cifPhonePairs || cifPhonePairs.length === 0) {
      return;
    }

    const payload = {
      voice_id: voiceId,
      cif_phone_pairs: cifPhonePairs,
    };
    setLoading(true);
    customerApi
      .linkVoiceToCustomer(payload)
      .then((resp) => {
        setLoading(false);
        setSelectedRowKeys([]);
        showSuccessNotification(t("LINK_SUCCESS"));
        getCustomerByPhone();
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(t("LINK_ERROR"));
      });
  };

  return (
    <>
      {transition((style, item) => {
        if (item) {
          return (
            <animated.div className={styles.container} style={style}>
              <span className="text_title">
                {t("CUSTOMER_SAME_PHONE_LIST")}
              </span>
              <span className="text-small-11">
                {t("TOTAL_RECORD")} {dataSource.length}
              </span>
              <Table
                dataSource={dataSource}
                loading={loading}
                bordered={false}
                columns={columns}
                rowSelection={rowSelection}
                pagination={false}
              />

              <Row justify="end">
                <Popconfirm
                  title={t("CONFIRM_ENROLL")}
                  onConfirm={handleConfirmEnroll}
                  okText={t("YES")}
                  cancelText={t("NO")}
                  disabled={selectedRowKeys.length === 0}
                >
                  <Button
                    type="primary"
                    ghost
                    disabled={selectedRowKeys.length === 0}
                  >
                    {t("LINK")}
                  </Button>
                </Popconfirm>
              </Row>
            </animated.div>
          );
        }
        return "";
      })}
    </>
  );
}

export default SameCustomerList;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "live",
  filterDates: null,
  filterCode: null,
};
const verifySlice = createSlice({
  name: "verify",
  initialState: initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setFilterDates: (state, action) => {
      state.filterDates = action.payload;
    },
    setFilterCode: (state, action) => {
      state.filterCode = action.payload;
    },
  },
});

export const verifyAction = verifySlice.actions;

export default verifySlice.reducer;

import React, { useState } from "react";
import { Row, Col, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined, FileExcelOutlined } from "@ant-design/icons";
import styles from "./UserFilter.module.css";
import Card from "components/ui/Card";
import TimeRange from "components/ui/TimeRange";
import userApi from "apis/user";

function FilterUser({ onSearch }) {
  const { t } = useTranslation();
  const [agentName, setAgentName] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [role, setRole] = useState(null);
  const [dates, setDates] = useState(null);

  const getPayload = () => {
    const payload = {};
    if (agentName && agentName !== "") {
      payload.username = agentName.trim();
    }
    if (agentId && agentId !== "") {
      payload.agent_id = agentId.trim();
    }
    if (role && role !== "") {
      payload.role = role.trim();
    }

    if (dates && dates.length) {
      const startDate = dates[0].format("YYYY-MM-DD HH:mm:ss");
      const endDate = dates[1].format("YYYY-MM-DD HH:mm:ss");
      payload.start_date = startDate;
      payload.end_date = endDate;
    }

    return payload;
  };

  const handleSearch = () => {
    const payload = getPayload();
    onSearch(payload);
  };

  const handleExport = () => {
    const payload = getPayload();
    userApi.exportUser(
      payload,
      t("EXPORT_USER_SUCCESS"),
      t("EXPORT_USER_FAIL")
    );
  };

  return (
    <Card>
      <span className="text_title">{t("FILTER_USER")}</span>
      <Row gutter={10}>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("USERNAME")}</span>
            <Input
              className="text"
              placeholder={t("USERNAME")}
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              allowClear
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("AGENT_ID")}</span>
            <Input
              className="text"
              placeholder={t("AGENT_ID")}
              value={agentId}
              onChange={(e) => setAgentId(e.target.value)}
              allowClear
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("ROLE")}</span>
            <Input
              className="text"
              placeholder={t("ROLE")}
              value={role}
              onChange={(e) => setRole(e.target.value)}
              allowClear
            />
          </div>
        </Col>
      </Row>

      <Row gutter={10} justify="space-between">
        <Col span={8}>
          <div className={styles.form_control}>
            <span className="label">{t("CREATED")}</span>
            <TimeRange dates={dates} onChangeDates={setDates} border />
          </div>
        </Col>
        <Col span={3}>
          <div className={styles.search_btn}>
            <Button
              icon={<FileExcelOutlined />}
              onClick={() => {
                handleExport();
              }}
            >
              {t("EXPORT_USER_REPORT")}
            </Button>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                handleSearch();
              }}
            >
              {t("SEARCH")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row></Row>
    </Card>
  );
}

export default FilterUser;

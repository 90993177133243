import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Drawer, Transfer, Input } from "antd";
import Scroll from "react-perfect-scrollbar";
import styles from "./EditPermission.module.css";
import userApi from "apis/user";
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from "components/ui/Notification";

const { TextArea } = Input;

function EditPermission({ visible, setVisible, data, setData, onSaveDone }) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permisisons, setPermissions] = useState([]);

  useEffect(() => {
    if (permisisons.length) {
      const permNames = permisisons.map((item) => {
        return {
          key: item.name,
          name: item.name,
        };
      });
      setDataSource(permNames);
    }
  }, [permisisons]);

  useEffect(() => {
    if (data) {
      const currentPermissions = [...data.permissions];
      setSelectedPermissions(currentPermissions);
      setName(data.role);
      setDescription(data.description);
    }
    getAllPermissions();
  }, []);

  const getAllPermissions = () => {
    userApi
      .getAllPermissions()
      .then((resp) => {
        const tmp = resp.map((item) => {
          item.key = item.name;
          return item;
        });
        setPermissions(tmp);
      })
      .catch((err) => {
        showErrorNotification(err.detail);
      });
  };

  const handleClose = () => {
    setVisible(false);
    if (setData) {
      setData(null);
    }
  };

  const filterOption = (inputValue, option) =>
    option.name.indexOf(inputValue) > -1;

  const handleSave = () => {
    if (data) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  const handleCreate = () => {
    if (!name || name === "") {
      showWarningNotification(t("REQUIRED_NAME"));
      return;
    }
    const payload = {
      name,
      description,
      permissions: selectedPermissions,
    };
    setLoading(true);
    userApi
      .createRole(payload)
      .then((resp) => {
        setLoading(false);
        showSuccessNotification(t("CREATE_ROLE_SUCCESS"));
        onSaveDone();
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    const payload = {
      name,
      description,
      permissions: selectedPermissions,
    };
    userApi
      .editPermissions(payload)
      .then((resp) => {
        setLoading(false);
        showSuccessNotification(t("EDIT_ROLE_SUCCESS"));
        onSaveDone();
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };

  return (
    <Drawer
      title={data ? t("EDIT_ROLE") : t("CREATE_ROLE")}
      open={visible}
      onClose={handleClose}
      closable={false}
      width={600}
      footer={
        <div className={styles.footer}>
          <Button onClick={handleClose}>{t("CANCEL")}</Button>
          <Button type="primary" onClick={handleSave} loading={loading}>
            {t("SAVE")}
          </Button>
        </div>
      }
    >
      <Scroll className={styles.container}>
        <div className={styles.form_control}>
          <span className={styles.required_label}>{t("NAME")}</span>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={data !== null && data !== undefined}
            className={styles.text}
            placeholder={t("NAME")}
          />
        </div>

        <div className={styles.form_control}>
          <span className={styles.required_label}>{t("DESCRIPTION")}</span>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={styles.text}
            placeholder={t("DESCRIPTION")}
          />
        </div>
        <div className={styles.form_control}>
          <span className={styles.required_label}>{t("PERMISSIONS")}</span>
          <Transfer
            dataSource={dataSource}
            titles={[t("NOT_SELECTED"), t("SELECTED")]}
            targetKeys={selectedPermissions}
            onChange={setSelectedPermissions}
            render={(item) => item.name}
            filterOption={filterOption}
            showSearch
            className={styles.text}
          />
        </div>
      </Scroll>
    </Drawer>
  );
}

export default EditPermission;

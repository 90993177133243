import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "antd";
import styles from "./VerifyAction.module.css";

function VerifyAction({
  isIframe,
  agentId,
  cif,
  phone,
  citizenId,
  agentName,
  ucid,
}) {
  const { t } = useTranslation();
  const verifyURL = `/verify?agentId=${agentId}&cif=${cif}&phone=${phone}&citizenId=${citizenId}&agentName=${agentName}&ucid=${ucid}`;
  const historyURL = "/history";
  const voiceURL = "/voice";
  return (
    <div className={styles.btn_container}>
      {isIframe ? (
        <Link to={verifyURL} target="_blank" rel="noopener noreferrer">
          <Button type="primary">{t("VIEW_VOICEDNA_HISTORY")}</Button>
        </Link>
      ) : (
        <Link to={historyURL}>
          <Button type="primary">{t("VIEW_VOICEDNA_HISTORY")}</Button>
        </Link>
      )}
      <Link to={voiceURL} target="_blank" rel="noopener noreferrer">
        <Button>{t("CANCEL_VOICE")}</Button>
      </Link>
    </div>
  );
}

export default VerifyAction;

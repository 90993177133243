import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import UserList from "./components/UserList";
import RoleList from "./components/RoleList";
import userApi from "apis/user";
import { userAction } from "store/userSlice";
import { showErrorNotification } from "components/ui/Notification";

function UserManagement() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const usersScroll = useRef();

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    setLoading(true);
    userApi
      .getRoles()
      .then((resp) => {
        setLoading(false);
        const tmpRoles = resp.map((item) => {
          item.key = item.role;
          return item;
        });
        dispatch(userAction.setRoles(tmpRoles));
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(t(err?.detail));
      });
  };
  return (
    <div className={styles.container}>
      <Tabs
        defaultActiveKey="1"
        // type="card"
        items={[
          {
            label: t("USER_MANAGEMENT"),
            key: "1",
            children: (
              <PerfectScrollBar
                className={styles.main}
                containerRef={(el) => (usersScroll.current = el)}
              >
                <UserList
                  scrollTop={() => {
                    const scroll = usersScroll.current;
                    if (scroll) {
                      scroll.scrollTop = 0;
                    }
                  }}
                />
              </PerfectScrollBar>
            ),
          },
          {
            label: t("ROLE_MANAGEMENT"),
            key: "2",
            children: (
              <PerfectScrollBar className={styles.main}>
                <RoleList getRoles={getRoles} loading={loading} />
              </PerfectScrollBar>
            ),
          },
        ]}
      />
    </div>
  );
}

export default UserManagement;

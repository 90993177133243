import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Scroll from "react-perfect-scrollbar";
import { Table } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import { ThreeDots } from "react-loader-spinner";
import styles from "./style.module.css";
import { getCallingUrl } from "utils/helper";
import Heath from "components/ui/Heath";
import Condition from "./components/Condition";
import EnrollResult from "./components/EnrollResult";
import VerifyResult from "./components/VerifyResult";
import { TOKEN_KEY } from "constants/global";

function Calling() {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: t("CALL_ID"),
      dataIndex: "call_id",
      key: "call_id",
      align: "center",
      render: (callId) => {
        return <span className="text">{callId}</span>;
      },
    },
    {
      title: t("AGENT_ID"),
      dataIndex: "agent_id",
      key: "agent_id",
      align: "center",
      render: (agentId) => {
        return <span className="text">{agentId}</span>;
      },
    },
    {
      title: t("PHONE"),
      dataIndex: "phone_number",
      key: "phone_number",
      align: "center",
      render: (phone) => {
        return <span className="text">{phone}</span>;
      },
    },
    {
      title: t("CIF"),
      dataIndex: "cif",
      key: "cif",
      align: "center",
      render: (cif) => {
        return <span className="text">{cif}</span>;
      },
    },
    {
      title: t("STEP"),
      dataIndex: "step",
      key: "step",
      align: "center",
      render: (step) => {
        return <span className="text">{step || "-"}</span>;
      },
    },
    {
      title: t("ENV"),
      dataIndex: "env_clean",
      key: "env_clean",
      align: "center",
      render: (env) => {
        const isClean = env === "1";
        return (
          <div className={styles.row}>
            {isClean ? (
              <>
                <Heath color="#4cd137" />
                <span className="text">{t("GOOD")}</span>
              </>
            ) : (
              <>
                <Heath color="#e84118" />
                <span className="text">{t("NOISY")}</span>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: t("SPEAKING"),
      dataIndex: "is_speaking",
      key: "is_speaking",
      align: "center",
      render: (speaking) => {
        const isSpeaking = speaking === "1";
        return (
          <div className={styles.row}>
            <SoundOutlined />
            {isSpeaking ? (
              <ThreeDots
                height="20"
                width="20"
                radius="9"
                color="#4cd137"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <div style={{ width: 20 }}></div>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const url = getCallingUrl();
    const ws = new WebSocket(url);
    const token = localStorage.getItem(TOKEN_KEY);

    const closeWebsocket = (websocket) => {
      if (websocket.readyState == WebSocket.OPEN) {
        websocket.send("close");
        websocket.close();
      }
    };

    ws.onopen = () => {
      ws.send(JSON.stringify({ token: token }));
    };

    ws.onmessage = (e) => {
      const tmp = JSON.parse(e.data);
      const data = tmp
        .filter((item) => item.hasOwnProperty("agent_id"))
        .map((item, idx) => {
          item.key = idx;
          return item;
        });
      setDataSource(data);
    };

    return () => {
      closeWebsocket(ws);
    };
  }, []);
  return (
    <Scroll className={styles.container}>
      <span className="text-small-11">
        {t("TOTAL_RECORD")} {dataSource.length}
      </span>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <div className={styles.row_container}>
              {record.step === "enroll" ? (
                <>
                  <Condition
                    isEnoughNetSpeech={record.enough_enroll_net_speech}
                    isCollectionVoice={record.enroll_collecting_voice}
                    isEnoughQuality={record.enroll_check_quality}
                  />
                  <EnrollResult
                    isEnrolled={record.enrolled}
                    countSentence={record.sentence_count}
                  />
                </>
              ) : (
                <>
                  <Condition
                    isEnoughNetSpeech={record.enough_verify_net_speech}
                    isCollectionVoice={record.verify_collecting_voice}
                    isEnoughQuality={record.verify_check_quality}
                  />
                  <VerifyResult
                    is10sNotMatch={record.last_10s_not_match}
                    is3TimeNotMatch={record.not_match_3times_in_day}
                    fistPassedScore={record.score_first_passed}
                    sencondFailedScore={record.score_second_failed}
                  />
                </>
              )}
            </div>
          ),
          rowExpandable: (record) =>
            record.step === "enroll" || record.step === "verify",
        }}
      />
    </Scroll>
  );
}

export default Calling;

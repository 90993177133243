import React, { useState, useEffect } from "react";
import { Form, Button, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import LoginImage from "assets/images/login.svg";
import { useAuthProvider } from "contexts/authContext";

function Login() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { login } = useAuthProvider();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (values) => {
    setLoading(true);
    login(values.username, values.password, () => {
      setLoading(false);
    });
  };
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);

  useEffect(() => {
    handleFormValueChange();
  }, []);

  const handleFormValueChange = () => {
    const username = form.getFieldValue("username");
    const password = form.getFieldValue("password");
    if (!username || username === "" || !password || password === "") {
      setIsDisabledSubmit(true);
    } else {
      setIsDisabledSubmit(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.left_container}>
        <img src={LoginImage} className={styles.login_img} />
      </div>
      <div className={styles.right_container}>
        <h1 className={styles.text_center}>{t("WELCOME_MSG")}</h1>
        <span>{t("LOGIN_DESC")}</span>
        <div className={styles.main}>
          <Form
            form={form}
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit}
            layout="vertical"
            onValuesChange={handleFormValueChange}
          >
            <Form.Item
              name="username"
              label={t("USERNAME")}
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_USERNAME"),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className={styles.icon} />}
                placeholder={t("USERNAME")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("PASSWORD")}
              rules={[
                {
                  required: true,
                  message: t("REQUIRED_PASSWORD"),
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className={styles.icon} />}
                placeholder={t("PASSWORD")}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                disabled={isDisabledSubmit}
                loading={loading}
              >
                {t("LOGIN")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;

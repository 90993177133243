import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import styles from "./HeaderExtra.module.css";
import { globalAction } from "store/globalSlice";
import { useAuthProvider } from "contexts/authContext";

function HeaderExtra() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isCollapse = useSelector((state) => state.global.isCollapse);
  const { currentUser } = useAuthProvider();
  const [pathElements, setPathElements] = useState([]);

  const pathMapping = {
    "/": t(currentUser?.menu[0].name),
    dashboard: t("DASHBOARD"),
    voice: t("VOICE_MNGT"),
    authorization: t("AUTHORIZATION"),
    requests: t("REQUEST"),
    verify: t("CUSTOMER_VERIFY"),
    enroll: t("CUSTOMER_ENROLLMENT"),
    voice: t("VOICE_MANAGEMENT"),
    history: t("VERIFY_HISTORY"),
    calling: t("CALLING"),
    logging: "Logging",
  };

  useEffect(() => {
    const tmpElements = location.pathname
      .split("/")
      .filter((element) => element.trim() !== "");
    if (tmpElements.length === 0) {
      tmpElements.push("/");
    }
    setPathElements([...tmpElements]);
  }, [location.pathname]);

  const getPath = (idx) => {
    const selectPaths = pathElements.slice(0, idx + 1);
    if (selectPaths.length >= 2) {
      return selectPaths.join("/").substring(1);
    }
    return selectPaths.join("/");
  };

  const hanleCollapseMenu = () => {
    dispatch(globalAction.setIsCollapse(!isCollapse));
  };

  return (
    <div className={styles.container}>
      <Button type="text" icon={<MenuOutlined />} onClick={hanleCollapseMenu} />
      <Breadcrumb separator="">
        {pathElements.map((element, idx) => (
          <Breadcrumb.Item key={idx}>
            <Link to={getPath(idx)}>{pathMapping[element]}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
}

export default HeaderExtra;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Select, InputNumber, Button } from "antd";
import PerfectScrollBar from "react-perfect-scrollbar";
import styles from "./Extension.module.css";

import useOutside from "hooks/useOutside";
import { getTime } from "utils/datetime";

const { Option } = Select;

function Extentsion({ setVisible, setDates }) {
  const { t } = useTranslation();

  const quickTimeOptions = [
    {
      name: t("HOUR"),
      value: "hours",
    },
    {
      name: t("DAY"),
      value: "days",
    },
    {
      name: t("WEEK"),
      value: "weeks",
    },
    {
      name: t("MONTH"),
      value: "months",
    },
    {
      name: t("YEAR"),
      value: "years",
    },
  ];

  const commonOptions = [
    {
      name: t("LAST_1_DAY"),
      value: 1,
      type: "days",
    },
    {
      name: t("LAST_3_DAY"),
      value: 3,
      type: "days",
    },
    {
      name: t("LAST_1_WEEK"),
      value: 1,
      type: "weeks",
    },
    {
      name: t("LAST_3_WEEK"),
      value: 3,
      type: "weeks",
    },
    {
      name: t("LAST_1_MONTH"),
      value: 1,
      type: "months",
    },
    {
      name: t("LAST_3_MONTH"),
      value: 3,
      type: "months",
    },
    {
      name: t("LAST_6_MONTH"),
      value: 6,
      type: "months",
    },
    {
      name: t("LAST_1_YEAR"),
      value: 1,
      type: "years",
    },
  ];

  const [quickValue, setQuickValue] = useState(1);
  const [quickOpt, setQuickOpt] = useState(quickTimeOptions[2].value);
  const containerRef = useRef(null);

  useOutside(containerRef, ["ant-select-item-option-content"], () => {
    setVisible(false);
  });

  const applyQuickFilter = () => {
    const time = getTime(quickValue, -1, quickOpt);
    setDates([time.start, time.end]);
    setVisible(false);
  };

  const applyCommonUsed = (idx) => {
    const selected = commonOptions[idx];
    const time = getTime(selected.value, -1, selected.type);
    setDates([time.start, time.end]);
    setVisible(false);
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <span className={styles.text_title}>{t("QUICK_SELECT")}</span>
      <PerfectScrollBar className={styles.quick_select_container}>
        <Select disabled={true} value={-1} className={styles.text}>
          <Option value={-1}>Last</Option>
        </Select>
        <InputNumber
          value={quickValue}
          onChange={setQuickValue}
          className={styles.input_number}
          min={1}
        />
        <Select value={quickOpt} onChange={setQuickOpt} className={styles.text}>
          {quickTimeOptions.map((item, idx) => {
            return (
              <Option value={item.value} key={idx}>
                {item.name}
              </Option>
            );
          })}
        </Select>

        <Button
          type="primary"
          ghost
          className={styles.text}
          onClick={() => {
            applyQuickFilter();
          }}
        >
          {t("APPLY")}
        </Button>
      </PerfectScrollBar>

      <span className={styles.text_title}>{t("COMMON_USED")}</span>
      <div className={styles.common_used_container}>
        {commonOptions.map((item, idx) => {
          return (
            <div key={idx}>
              <span
                className={styles.text_common}
                onClick={() => {
                  applyCommonUsed(idx);
                }}
              >
                {item.name}
              </span>
            </div>
          );
        })}
      </div>

      <div className={styles.footer}>
        <Button
          size="small"
          onClick={() => {
            setVisible(false);
          }}
        >
          {t("CLOSE")}
        </Button>
      </div>
    </div>
  );
}

export default Extentsion;

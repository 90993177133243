import axiosClient from "./axiosClient";

const authApi = {
  login: (username, password) => {
    const url = "/api/v1/auth/login";
    const payload = {
      username,
      password,
    };
    return axiosClient.post(url, payload);
  },
  logout: () => {
    const url = "/api/v1/auth/logout";
    return axiosClient.get(url);
  },
  getUser: () => {
    const url = "/api/v1/auth/user";
    return axiosClient.get(url);
  },
};

export default authApi;

import React from "react";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./Condition.module.css";

function Condition({ isEnoughNetSpeech, isCollectionVoice, isEnoughQuality }) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h3>{t("CONDITION")}</h3>
      <Checkbox
        checked={isEnoughNetSpeech === "1"}
        style={{ marginLeft: "0.8rem", marginTop: "1rem" }}
      >
        {t("ENOUGH_NET_SPEECH")}
      </Checkbox>
      <Checkbox checked={isCollectionVoice === "1"}>
        {t("COLLECTED_VOICE")}
      </Checkbox>
      <Checkbox checked={isEnoughQuality === "1"}>
        {t("CHECKED_QUALITY")}
      </Checkbox>
    </div>
  );
}

export default Condition;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};
const requestSlice = createSlice({
  name: "request",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const requestAction = requestSlice.actions;

export default requestSlice.reducer;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Verify.module.css";
import VerifyAction from "./VerifyAction";
import { Status } from "../status";
import Step from "./Step";

function Verify({
  cif,
  phone,
  agentId,
  citizenId,
  status,
  type,
  agentName,
  ucid,
}) {
  const { t } = useTranslation();
  const totalStep = 2;
  const [isEnoughCondition, setIsEnoughCondition] = useState(null);

  useEffect(() => {
    const failedStatus = status.find(
      (item) => item.value === Status.FAILED && item.type === "status"
    );
    if (failedStatus) {
      setIsEnoughCondition(false);
    } else {
      setIsEnoughCondition(true);
    }
  }, [status]);

  const renderMessage = () => {
    if (isEnoughCondition === false) {
      return <span className="text_error">{t("NOT_ENOUGH_VERIFY")}</span>;
    }
  };
  return (
    <div className={styles.container}>
      {type === "iframe" && (
        <div className={styles.header}>
          <span className={styles.text_title}>{t("CUSTOMER_ENROLLED")}</span>
        </div>
      )}
      <div className={styles.message_container}>
        {status.map((item, idx) => {
          if (item.type === "status" && idx < totalStep) {
            return (
              <Step
                step={item.step}
                value={item.value}
                numberStep={totalStep}
                key={idx}
                idx={idx}
              />
            );
          } else if (item.type === "score") {
            if (item?.result) {
              return (
                <span key={idx} className={`text_success ${styles.text_score}`}>
                  {`${t("VOICE_MATCHED")}:`} {item.value}
                </span>
              );
            } else {
              return (
                <span key={idx} className={`text_error ${styles.text_score}`}>
                  {`${t("VOICE_NOT_MATCHED")}:`} {item.value}
                </span>
              );
            }
          } else if (item.type === "error") {
            return (
              <span key={idx} className={`text_error ${styles.text_score}`}>
                {t(item.step)}
              </span>
            );
          }
        })}
      </div>
      <div className={styles.action_container}>
        {renderMessage()}
        <VerifyAction
          agentId={agentId}
          cif={cif}
          phone={phone}
          isIframe={type === "iframe"}
          citizenId={citizenId}
          agentName={agentName}
          ucid={ucid}
        />
      </div>
    </div>
  );
}

export default Verify;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "antd";
import styles from "./EnrollAction.module.css";
import voiceApi from "apis/voice";
import customerApi from "apis/customer";
import {
  showConfirmPopup,
  showErrorNotification,
} from "components/ui/Notification";

function EnrollAction({
  isIframe,
  isEnrolled,
  setIsEnrolled,
  agentId,
  cif,
  phone,
  phoneType,
  citizenId,
  ucid,
  canEnroll,
  agentName,
  onSave,
}) {
  return (
    <>
      {isIframe ? (
        <IframeEnrollAction
          agentId={agentId}
          cif={cif}
          phone={phone}
          citizenId={citizenId}
          ucid={ucid}
          canEnroll={canEnroll}
          isEnrolled={isEnrolled}
          phoneType={phoneType}
          agentName={agentName}
        />
      ) : (
        <VoiceDNAEnrollAction
          agentId={agentId}
          cif={cif}
          phone={phone}
          canEnroll={canEnroll}
          isEnrolled={isEnrolled}
          setIsEnrolled={setIsEnrolled}
          onSave={onSave}
          ucid={ucid}
        />
      )}
    </>
  );
}

function IframeEnrollAction({
  agentId,
  cif,
  phone,
  citizenId,
  canEnroll,
  isEnrolled,
  phoneType,
  agentName,
  ucid,
}) {
  const { t } = useTranslation();
  const enrollURL = `/enroll?agentId=${agentId}&cif=${cif}&phone=${phone}&citizenId=${citizenId}&phoneType=${phoneType}&agentName=${agentName}&ucid=${ucid}`;
  const historyURL = "/history";
  return (
    <>
      {isEnrolled ? (
        <Link to={historyURL} target="_blank" rel="noopener noreferrer">
          <Button type="primary">{t("VIEW_VOICEDNA_HISTORY")}</Button>
        </Link>
      ) : (
        <Button
          type="primary"
          disabled={!canEnroll}
          onClick={() => {
            window.open(enrollURL, "_blank");
          }}
        >
          {t("ENROLL")}
        </Button>
      )}
    </>
  );
}

function VoiceDNAEnrollAction({
  agentId,
  cif,
  phone,
  canEnroll,
  isEnrolled,
  setIsEnrolled,
  ucid,
  onSave,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const handleEnroll = () => {
    // if (currentUser) {
    setLoading(true);
    voiceApi
      .enroll(ucid, phone)
      .then((resp) => {
        const voiceId = resp.voiceid;
        if (voiceId) {
          const payload = {
            voice_id: voiceId,
            cif_phone_pairs: [
              {
                cif: cif,
                phone: phone,
              },
            ],
          };

          customerApi
            .linkVoiceToCustomer(payload)
            .then((resp) => {
              setLoading(false);
              setIsEnrolled(true);
              if (onSave) {
                onSave(true, voiceId);
              }
            })
            .catch((err) => {
              setLoading(false);
              showErrorNotification(t(err.detail));
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
    // }
  };

  const handleCancel = () => {
    showConfirmPopup(
      t("CONFIRM_REENROLL"),
      () => {
        voiceApi.restartCall(ucid);
      },
      t("YES"),
      t("NO")
    );
  };

  return (
    <>
      {isEnrolled ? (
        <></>
      ) : (
        <>
          <div className={styles.btn_container}>
            <Button onClick={handleCancel}>{t("CANCEL_ENROLL")}</Button>
            <Button
              type="primary"
              loading={loading}
              disabled={!canEnroll}
              onClick={handleEnroll}
            >
              {t("ENROLL")}
            </Button>
          </div>
          <span className="text_error">{t("WARNING_OWN_VOICE")}</span>
        </>
      )}
    </>
  );
}

export default EnrollAction;

import React, { useState } from "react";
import { Pagination } from "antd";
import Card from "components/ui/Card";
import { useTranslation } from "react-i18next";
import Detail from "./Detail";
import VerifyResultTable from "components/ui/VerifyResultTable";

function HistoryList({
  dataSource,
  loading,
  total,
  page,
  pageSize,
  onChangePage,
}) {
  const { t } = useTranslation();
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [selectedAudioPaths, setSelectedAudioPaths] = useState(null);

  return (
    <Card>
      <span className="text_title">{t("VERIFY_HISTORY")}</span>
      <span className="text-small-11">
        {t("TOTAL_RECORD")} {total}
      </span>

      {visibleDetail && selectedAudioPaths !== null && (
        <Detail
          visible={visibleDetail}
          setVisible={setVisibleDetail}
          selectedAudioPaths={selectedAudioPaths}
          setSelectedAudioPaths={setSelectedAudioPaths}
        />
      )}

      <VerifyResultTable
        dataSource={dataSource}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              // setVisibleDetail(true);
              // setSelectedAudioPaths(record.audio_paths);
            },
          };
        }}
      />

      <div className="page_container">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={total}
          onChange={onChangePage}
        />
      </div>
    </Card>
  );
}

export default HistoryList;

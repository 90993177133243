import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Enroll.module.css";
import { Status, EnrollStep } from "../status";
import EnrollAction from "./EnrollAction";
import Step from "./Step";

function Enroll({
  cif,
  phone,
  agentId,
  status,
  citizenId,
  phoneType,
  type,
  agentName,
  onSave,
  ucid,
}) {
  const { t } = useTranslation();
  const [isEnoughCondition, setIsEnoughCondition] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const totalStep = 3;

  useEffect(() => {
    const isFailed = status.find((item) => item.value === Status.FAILED);
    if (isFailed) {
      setIsEnoughCondition(false);
    } else {
      setIsEnoughCondition(true);
    }

    // Check is enrolling status
    let enrollStatus = false;
    if (status.length < totalStep) {
      enrollStatus = false;
      if (onSave) {
        onSave(enrollStatus);
      }
    } else {
      const lastStatus = status[status.length - 1];
      if (
        lastStatus.step === EnrollStep.ENROLLING &&
        lastStatus.value === Status.SUCCESS
      ) {
        enrollStatus = true;
      }
    }

    setIsEnrolled(enrollStatus);
  }, [status]);

  const renderMessage = () => {
    if (isEnoughCondition === false) {
      return <span className="text_error">{t("NOT_ENOUGH_ENROLL")}</span>;
    }

    if (isEnrolled) {
      return <span className="text_success">{t("ENROLLED_SUCCESS")}</span>;
    }
  };

  return (
    <div className={styles.container}>
      {type === "iframe" && (
        <div className={styles.header}>
          <span className={styles.text_title}>{t("CUSTOMER_NOT_ENROLL")}</span>
        </div>
      )}
      <div className={styles.message_container}>
        {status.map((item, idx) => {
          if (idx < totalStep && item.type === "status") {
            return (
              <Step
                idx={idx}
                key={idx}
                value={item.value}
                step={item.step}
                numberStep={totalStep}
              />
            );
          }
        })}
      </div>
      <div className={styles.action_container}>
        {renderMessage()}
        <EnrollAction
          agentId={agentId}
          cif={cif}
          phone={phone}
          phoneType={phoneType}
          citizenId={citizenId}
          ucid={ucid}
          isEnrolled={isEnrolled}
          setIsEnrolled={setIsEnrolled}
          isIframe={type === "iframe"}
          onSave={onSave}
          agentName={agentName}
          canEnroll={
            status.every((item) => item.value === Status.SUCCESS) &&
            status.length == totalStep
          }
        />
      </div>
    </div>
  );
}

export default Enroll;
